import { combineReducers } from "redux";
import { MenuReducer } from "./menuReducer";
import { ApiPanierReducer } from "./apiPanierReducer";
import CardReducer from "./card";
import authReducer from "./auth";

const rootReducer = combineReducers({
  //   menuReducer: MenuReducer,
  //   ApiPanierReducer: ApiPanierReducer,
  CardReducer,
  authReducer,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export { rootReducer };
