import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Avatar } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";
import { Mousewheel, Pagination } from "swiper";
import {
  ProductDetailsCouleur,
  ProductDetailsModel,
} from "../../../../types/productDetailsModels/productDetailsModel";
import CircularProgress from "@mui/material/CircularProgress";
import { PhotoProductCouleurListModel } from "../../../../types/productDetailsModels/photoProductCouleurModel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { userInfoType } from "../../../../types/user/user";
import useStyles from "./styles";
// componentDidMount

interface ProductDetailsProps {
  productDetails: ProductDetailsModel;
  productDetailsId: string | undefined;
  setColorId: React.Dispatch<React.SetStateAction<number | null>>;
}

const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

export default function ProductDetailsCarousel({
  productDetails,
  productDetailsId,
  setColorId,
}: ProductDetailsProps) {
  const [productDetailsColors, setProductDetailsColors] = useState<
    ProductDetailsCouleur[] | null
  >(productDetails.couleurs as ProductDetailsCouleur[]);
  const [selectedColor, setSelectedColor] =
    useState<ProductDetailsCouleur | null>(null);

  const [photoProductCouleurList, setPhotoProductCouleurList] = useState<
    PhotoProductCouleurListModel | null | "loading"
  >("loading");

  const [isFavorite, setIsFavorite] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsFavorite(productDetails.favoris);
    setProductDetailsColors(productDetails.couleurs);

    // setColorId(selectedColor!.id);

    const requestOptions = {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(
      selectedColor
        ? process.env.REACT_APP_API_URL +
            `/api/photo-produit-couleur/?couleur=${selectedColor.id}&produit=${productDetailsId}`
        : process.env.REACT_APP_API_URL +
            `/api/photo-produit-couleur/?produit=${productDetailsId}`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setPhotoProductCouleurList(data as PhotoProductCouleurListModel);
        console.log("data is ", data);

        // check if user is logged in
      })
      .catch((error) => {
        console.log("error is ", error);
      });
  }, [
    selectedColor?.id,
    productDetailsId,
    // productDetailsColors,
    productDetails.favoris,
    selectedColor,
  ]);

  const choiceColorInTheFirstTime = (color: ProductDetailsCouleur) => {
    setSelectedColor(color);
    setColorId(color.id);
  };

  const setOrDeleteFavorite = () => {
    setIsFavorite(!isFavorite);

    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
      body: JSON.stringify({
        produit: productDetailsId,
      }),
    };

    fetch(
      process.env.REACT_APP_API_URL + "/api/userfavoris/setfavoris/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error.message);
        console.log("error is ", error);
      });
  };

  // const photoProductCouleurList =
  //   dataProductsColors as unknown as PhotoProductCouleurListModel;
  return (
    <>
      <div className={classes.root}>
        {productDetails.is_new && (
          <Typography
            variant="caption"
            style={{
              color: "#fff",
              position: "absolute",
              top: 6,
              right: 6,
              zIndex: 999,
              backgroundColor: "#f44336",
              paddingLeft: 4,
              paddingRight: 6,
              borderRadius: 6,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            Nouveau
          </Typography>
        )}
        {productDetails.promotion && (
          <Typography
            variant="caption"
            style={{
              color: "#fff",
              position: "absolute",
              top: productDetails.is_new ? 30 : 6,
              right: 6,
              zIndex: 999,
              backgroundColor: "#f44336",
              paddingLeft: 4,
              paddingRight: 6,
              borderRadius: 6,
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            Soldé
          </Typography>
        )}
        {isFavorite || productDetails.favoris ? (
          <FavoriteIcon
            className={classes.favoriteIcon}
            style={{
              zIndex: "10",
            }}
            onClick={() => {
              setOrDeleteFavorite();
            }}
          />
        ) : (
          <FavoriteBorderIcon
            className={classes.favoriteIcon}
            style={{
              zIndex: "10",
            }}
            onClick={() => {
              setOrDeleteFavorite();
            }}
          />
        )}
        <div
          style={{
            position: "relative",
            backgroundColor: "#e8e8e8",
            height: "95%",
            width: "100%",
          }}
        >
          <Swiper
            direction={"vertical"}
            slidesPerView={1}
            spaceBetween={0}
            mousewheel={true}
            pagination={{
              clickable: true,
            }}
            style={{
              position: "relative",
              width: "100%",
            }}
            // last slide

            onReachEnd={(swiper) => {
              swiper.mousewheel.disable();
              swiper.allowTouchMove = false;
            }}
            // on scroll up
            onTouchMove={(swiper) => {
              swiper.mousewheel.enable();
            }}
            onReachBeginning={(swiper) => {
              swiper.allowTouchMove = true;
            }}
            onTouchMoveOpposite={(swiper) => {
              swiper.mousewheel.disable();
              swiper.allowTouchMove = true;
            }}
            modules={[Mousewheel, Pagination]}
            className="mySwiper"
          >
            <Typography variant="h5" component="h2">
              {productDetails.nom}
            </Typography>
            {photoProductCouleurList === "loading" ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  zIndex: "1000",
                  justifyContent: "center",
                  background: "red",
                }}
              >
                <CircularProgress disableShrink />
              </div>
            ) : (
              photoProductCouleurList &&
              photoProductCouleurList?.map((photoProductCouleur) => (
                <SwiperSlide>
                  <img
                    src={photoProductCouleur.picture}
                    srcSet={photoProductCouleur.picture}
                    alt={photoProductCouleur.code_couleur}
                    loading="lazy"
                    className={classes.image}
                  />
                </SwiperSlide>
              ))
            )}
            {/* colors select */}
          </Swiper>
        </div>
      </div>
      <Stack direction="row" className={classes.colorsContainer}>
        {((selectedColor === null &&
          productDetailsColors &&
          choiceColorInTheFirstTime(
            productDetailsColors[0] as ProductDetailsCouleur
          )) ||
          productDetailsColors) &&
          productDetailsColors.map((color: ProductDetailsCouleur) => (
            <Avatar
              onClick={() => {
                console.log(" ------ selected color ------ : ", color.id);
                setSelectedColor(color);
                setColorId(color.id);
                setPhotoProductCouleurList("loading");
                document.body.scrollTop = 0;
              }}
              key={color.id}
              style={{
                position: "relative",
                cursor: "pointer",
                backgroundColor:
                  color.code_couleur.indexOf("#") === -1
                    ? "#" + color.code_couleur
                    : color.code_couleur,
                borderRadius: "50%",
                width: "30px",
                height: "30px",
              }}
            >
              <CheckIcon
                sx={
                  color.id === selectedColor?.id
                    ? {
                        position: "relative",
                        top: "0",
                        left: "0",
                        color: "white",
                        fontSize: "1.5rem",
                        zIndex: "1",
                        height: "58%",
                        width: "58%",
                      }
                    : {
                        display: "none",
                      }
                }
              />
            </Avatar>
          ))}
      </Stack>
    </>
  );
}
