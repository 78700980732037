import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  commandeContainerCard: {
    marginLeft: "100px",
    marginRight: "100px",
    marginTop: "20px",
    width: "50%",
    alignContent: "center",
    justifyContent: "center",
    items: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  commandeContainerCard__loading: {
    marginLeft: "100px",
    marginRight: "100px",
    marginTop: "20px",
    width: "50%",
    minWidth: "760px",
    alignContent: "center",
    justifyContent: "center",
    items: "center",
    backgroundColor: "red",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      width: "100%",
    },
  },

  commandeContainerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "30px",
    marginRight: "30px",
  },

  commandeContainerStackButton: {
    justifyContent: "space-evenly",
    marginBottom: "20px",
    marginLeft: "30px",
    marginRight: "30px",
  },

  commandesRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  card__content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  card__content__image: {
    width: "180px",
    height: "180px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  loading__card__content__stack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
    },
  },

  loading__card__content__box: {
    width: "300px",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));
