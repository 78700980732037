import { type } from "os";
import React, { useEffect, useState } from "react";

type LocationModel = {
  latitude: number;
  longitude: number;
  address: string;
};

export const GetLocation = () => {
  const [location, setLocation] = useState<LocationModel | null>(null);
  return (
    <div>
      {/* <h2>Get Location</h2> */}
      {/* <button onClick={() => getLocation(setLocation)}>Get Location</button> */}
      {/* <p>latitude: {location?.latitude}</p>
      <p>longitude: {location?.longitude}</p>
      <p>address: {location?.address}</p> */}
    </div>
  );
};
