import React from "react";
import { LoadingCard } from "./LoadingCard";
import useStyles from "./styles";

export const LodingCardsList = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
        <LoadingCard key={item} />
      ))}
    </div>
  );
};
