import {
  AppBar,
  Badge,
  Slide,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from "@material-ui/core";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
// import componentDidMount

import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/pngs/logo.png";
import useStyles from "./styles";
import { ShoppingCart, FavoriteBorderOutlined } from "@material-ui/icons";
import SecondNavBar from "./SecondNavBar";
import { Link } from "react-router-dom";
import { useStateContext } from "../../context/stateContext";
import MenuOptions from "./MenuOptions";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { SearchModel } from "../../types/SearchModels/SearchModel";
import { SearchModal } from "./SearchModal";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");
  const [openSearchModal, setOpenSearchModal] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchData, setSearchData] = React.useState<SearchModel | null>(null);

  const {
    totalQuantity,
    showCart,
    setShowCart,
    setTotalQuantity,
    userInfo,
    setUserInfo,
  } = useStateContext();

  const {
    data: panierData,
    isLoading: panierIsLoading,
    isError: panierIsError,
    isSuccess: panierIsSuccess,
    refetch: panierRefetch,
  }: UseQueryResult<number, Error> = useQuery<number, Error>(
    "panier",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/panier/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data.length;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      onSuccess: (data) => {
        console.log("data is ", data);
        setTotalQuantity(data);
      },
    }
  );

  useEffect(() => {
    panierRefetch();
  }, [showCart, setTotalQuantity, totalQuantity]);

  useEffect(() => {
    try {
      let userInfoTmp = null;
      if (localStorage?.getItem("profile") !== null) {
        userInfoTmp = JSON.parse(localStorage?.getItem("profile") ?? "");
      }
      if (userInfoTmp) {
        setUserInfo(userInfoTmp);
      }
    } catch (error) {
      setUserInfo(null);
    }
  }, [location, setUserInfo]);

  const {
    data: searchDataQuery,
    isLoading: searchIsLoading,
    isError: searchIsError,
    isSuccess: searchIsSuccess,
    refetch: searchRefetch,
  } = useQuery<SearchModel, Error>(
    `search ${searchQuery}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/produit?search=${searchQuery}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    },
    {
      enabled: searchQuery !== "",
      onSuccess: (data) => {
        setSearchData(data);
        setOpenSearchModal(true);
      },
    }
  );

  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        className={classes.appBar}
        elevation={0}
        color="inherit"
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6">
            <Link
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
              to={`/`}
            >
              <img
                src={logo}
                alt="logo"
                height="25px"
                className={classes.image}
              />
            </Link>
          </Typography>
          {openSearchModal &&
          searchData &&
          searchData.results?.length &&
          searchQuery.length > 0 ? (
            // setOpenSearchModal, setSearchQuery, setSearchData, searchData,
            <SearchModal
              setOpenSearchModal={setOpenSearchModal}
              setSearchQuery={setSearchQuery}
              setSearchData={setSearchData}
              searchData={searchData}
            />
          ) : null}

          {!isMobileOrTablet && (
            <Paper
              component="form"
              sx={{
                mt: 1,
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "50%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="recherche "
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSearchQuery(e.target.value);
                  searchRefetch();
                }}
                // on key press enter
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    console.log(e.target);
                  }
                }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon onClick={() => searchRefetch()} />
              </IconButton>
            </Paper>
          )}
          <div className={classes.menu}>
            {userInfo ? (
              <MenuOptions />
            ) : (
              <Typography
                component={Link}
                to="login"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                LOGIN
              </Typography>
            )}
            {userInfo && (
              <IconButton
                area-label="user"
                color="inherit"
                onClick={() => {
                  if (userInfo) {
                    navigate("favoris");
                  } else {
                    toast.error(
                      "Veuillez vous connecter pour accéder à cette page"
                    );
                  }
                }}
              >
                <FavoriteBorderOutlined />
              </IconButton>
            )}
            {userInfo && (
              <IconButton
                className={classes.shopping__cart}
                onClick={() => {
                  if (totalQuantity > 0) {
                    setShowCart(!showCart);
                    // showCart
                    //   ? (document.body.style.overflow = "auto")
                    //   : (document.body.style.overflow = "hidden");
                    // document.body.scrollTop = 0;
                    // document.documentElement.scrollTop = 0;
                  } else {
                    toast.error("Your cart is empty");
                  }
                }}
                area-label="Show cart items"
                color="inherit"
              >
                <Badge
                  badgeContent={totalQuantity}
                  color="secondary"
                  overlap="rectangular"
                >
                  <ShoppingCart />
                </Badge>
              </IconButton>
            )}
          </div>
        </Toolbar>

        {window.location.pathname !== "/login" && <SecondNavBar />}
      </AppBar>
    </HideOnScroll>
  );
};

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default Navbar;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
