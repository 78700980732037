import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Rating from "@mui/material/Rating";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import Stack from "@mui/material/Stack";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ProductDetailsModel } from "../../../../types/productDetailsModels/productDetailsModel";
import { useStateContext } from "../../../../context/stateContext";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { ProductColorStock } from "../../../../types/ProductColorStock/product_color_stock";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { UserModel } from "../../../../types/UserModels/UserModel";
import useStyles from "./styles";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";

interface ProductDetailsProps {
  productDetails: ProductDetailsModel;
  colorId: number | null;
}

type userInfoType = {
  nom: string;
  givenName: string;
  familyName: string;
  token: string;
};

export const ProductDetailsDesription = ({
  productDetails,
  colorId,
}: ProductDetailsProps) => {
  // const [productColorStock, setProductColorStock] =
  //   React.useState<ProductColorStock | null>(null);
  const [attributes, setAttributes] = React.useState<number | null>(null);
  const [userInfo, setUserInfo] = React.useState<UserModel | null>(null);
  const [isLogged, setIsLogged] = React.useState(false);
  const classes = useStyles();
  const [promotionLeft, setPromotionLeft] = React.useState<string | null>(null);

  useEffect(() => {
    const userinfo = localStorage.getItem("profile");
    if (userinfo) {
      setUserInfo(JSON.parse(userinfo));
      setIsLogged(true);
    }

    let present_date = new Date();
    let today = `${present_date.getFullYear()}-${present_date.getMonth()}-${present_date.getDay()}`;
    if (productDetails.promotion) {
      let difirence =
        new Date(productDetails.promotion.date_fin).getTime() -
        new Date(today).getTime();

      const promotion_difirence_days = Math.ceil(
        difirence / (1000 * 3600 * 24)
      );
      const promotion_difirence_hours = Math.ceil(difirence / (1000 * 3600));
      const promotion_difirence_minutes = Math.ceil(difirence / (1000 * 60));
      const promotion_difirence_seconds = Math.ceil(difirence / 1000);

      if (promotion_difirence_days) {
        setPromotionLeft(`${promotion_difirence_days} jours restants`);
      } else if (promotion_difirence_hours) {
        setPromotionLeft(`&{promotion_difirence_hours} heures restantes`);
      } else if (promotion_difirence_minutes) {
        setPromotionLeft(`&{promotion_difirence_minutes} minutes restantes`);
      } else if (promotion_difirence_seconds) {
        setPromotionLeft(`&{promotion_difirence_seconds} secondes restantes`);
      } else {
        setPromotionLeft("Promotion expirée");
      }
    }

    refetchUserData();
    refetchProductColorStocks();
  }, [productDetails, colorId]);

  const {
    data: dataProductColorStocks,
    isSuccess: isSuccessProductColorStocks,
    refetch: refetchProductColorStocks,
  }: UseQueryResult<ProductColorStock, Error> = useQuery<
    ProductColorStock,
    Error
  >(
    `productColorStocks-${colorId}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/produit-couleur-stock/${colorId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      enabled: !!userInfo?.id,
      onSuccess: (data) => {
        // console.log("data is ", data);
      },
    }
  );

  const {
    data: userData,
    isSuccess: isSuccessUserData,
    refetch: refetchUserData,
    status: statusUserData,
  }: UseQueryResult<UserModel, Error> = useQuery<UserModel, Error>(
    `userData-${userInfo?.id}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/me/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      enabled: !!userInfo?.id,
      onSuccess: (data) => {
        setUserInfo(data);
      },
    }
  );

  const { qtty, setQtty, totalQuantity, setTotalQuantity } = useStateContext();
  const [addProductToCart, setAddProductToCart] = React.useState(false);

  const onAddToCart = (productDetails: ProductDetailsModel, qtty: number) => {
    setAddProductToCart(true);
    if (statusUserData === "loading") {
      toast.error("Veuillez patienter");
    } else if (statusUserData === "error") {
      toast.error("Une erreur est survenue");
    }
  };

  const {
    data: dataAddToCart,
    isSuccess: isSuccessAddToCart,
    status: statusAddToCart,
    isError: isErrorAddToCart,
    refetch: refetchAddToCart,
  } = useQuery<ProductColorStock, Error>(
    `addToCart-${attributes}`,
    async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/panier/`,
        {
          quantite: 1,
          user: userInfo?.id,
          produitcouleurattribut: attributes,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      // enabled: !!attributes,
      // enabled: false,
      enabled: addProductToCart,
      onSuccess: (data) => {
        if (qtty < 1 && totalQuantity < 1) {
          setTotalQuantity(1);
          setQtty(1);
        }
        setTotalQuantity(totalQuantity + qtty);
        console.log("our total quantity and qtty is ", totalQuantity, qtty);
        toast.success("Produit ajouté au panier");
      },
      onError: (error) => {
        console.log("add to cart error is ", error.name);
        toast.error(error.message);
      },
    }
  );

  return (
    <>
      <Card className={classes.root} elevation={0}>
        <CardContent>
          <h2 className="title-text mt-0 pt-0">
            {productDetails.nom && productDetails.nom.toUpperCase()}
          </h2>

          {/* discription: {productDetails.description} */}
          <h5 className="text-body">
            {productDetails.description && productDetails.description}
          </h5>
          <Card elevation={0} className={classes.priceContainer}>
            <Typography variant="body2" className={classes.priceText}>
              {productDetails.promotion ? (
                <>
                  <span className={classes.through__price}>
                    {productDetails.prix} MAD
                  </span>
                  <span>{productDetails.promotion.prix} MAD</span>
                </>
              ) : (
                <span>{productDetails.prix} MAD</span>
              )}
            </Typography>
            <Typography variant="body2" className={classes.taxText}>
              SKU: 21-0875
            </Typography>
          </Card>
          {productDetails.promotion && (
            <h5 className="text-body-red mt-1 mb-1">
              {promotionLeft} pour profiter de la promotion !
            </h5>
          )}
          <Card
            elevation={0}
            style={{
              marginTop: "0.5rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h5 className="text-body" style={{ fontWeight: "500" }}>
              Taxes incluses.
            </h5>
            {/* <Card elevation={0} className={classes.reviewsContainer}>
              <Rating name="hover-feedback" value={5} precision={1} />
              <Typography variant="h6" style={{ fontWeight: "100" }}>
                (52 reviews)
              </Typography>
            </Card> */}
          </Card>
          {isSuccessProductColorStocks && (
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className={classes.stockEmpty}
              >
                {dataProductColorStocks.type_attribut_required ? (
                  dataProductColorStocks.type_attribut_required!.nom
                ) : (
                  <h3 className="text-muted" style={{ fontSize: "1rem" }}>
                    desolé, ce produit n'a pas dans le stock pour le moment
                  </h3>
                )}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {dataProductColorStocks!.produitcouleurattributs &&
                  dataProductColorStocks!.produitcouleurattributs.map(
                    (item) => (
                      <FormControlLabel
                        key={item.id}
                        disabled={item.stock === 0}
                        value={item.id}
                        control={<Radio />}
                        label={item.attribut.nom}
                        onClick={() => {
                          setAttributes(item.id!);
                        }}
                      />
                    )
                  )}
              </RadioGroup>
            </FormControl>
          )}
          <Stack
            direction="row"
            spacing={qtty > 9 ? 3 : 4}
            sx={{
              marginBottom: ".5rem",
              items: "center",
              alignItems: "center",
            }}
          ></Stack>
          <Button
            onClick={() => {
              if (!isLogged) {
                toast.error("S'il vous plaît connectez-vous d'abord");
              } else {
                if (attributes) {
                  refetchAddToCart();
                  onAddToCart(productDetails, qtty);
                } else {
                  toast.error("Please select a attribute");
                }
              }
            }}
            style={{
              borderRadius: "0",
            }}
            variant="outlined"
            disabled={attributes ? false : true}
          >
            <h3
              className="menu-button-text m-0"
              style={{ color: attributes ? "#000" : "#ccc" }}
            >
              Ajouter au panier
            </h3>
          </Button>

          <h5 className="text-body mt-2">
            <span className="orange">
              S'il vous plaît noter que les couleurs et les propriétés peuvent
            </span>{" "}
            en fonction de votre écran.
          </h5>
          <Accordion sx={{ marginTop: "1.4rem" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {/* <ArticleRoundedIcon /> */}
              <FeedOutlinedIcon sx={{ marginRight: "0.5rem" }} />
              <h3 className="menu-button-text m-0">Aperçu</h3>
            </AccordionSummary>
            <AccordionDetails>
              <h5 className="text-body">
                {productDetails.description && productDetails.description}
              </h5>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StraightenOutlinedIcon sx={{ marginRight: "0.5rem" }} />
              <h3 className="menu-button-text m-0">Détails</h3>
            </AccordionSummary>
            <AccordionDetails>
              <h3 className="text-body">
                Notre tapis de yoga est fabriqué à partir de matériaux de haute
                qualité et est conçu pour durer.
              </h3>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <DownloadingRoundedIcon sx={{ marginRight: "0.5rem" }} />
              <h3 className="menu-button-text m-0">Livraison</h3>
            </AccordionSummary>
            <AccordionDetails>
              <h3 className="text-body">
                Nous offrons une livraison gratuite à partir de 1000 MAD d'achat
                dans les villes suivantes: Casablanca, Rabat, Marrakech, Tanger,
                Fès, Meknès, Agadir, Oujda, Tétouan, Kenitra, Mohammedia,
                Khémisset, El Jadida, Safi, Salé, Nador, Kénitra, Beni Mellal,
                Témara, Larache, Settat, Ksar El Kebir, Meknès, Mohammedia,
                Oujda, Ouarzazate, Khouribga et Berkane.
              </h3>
            </AccordionDetails>
            <AccordionDetails></AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <LocalShippingOutlinedIcon sx={{ marginRight: "0.5rem" }} />
              <h3 className="menu-button-text m-0">Retours</h3>
            </AccordionSummary>

            <AccordionDetails>
              <h3 className="text-body">
                En cas de problème avec votre commande, vous pouvez nous
                contacter par <br /> email à l'adresse suivante:
                support@icebergtech.net ou par <br /> téléphone au 06 66 66 66
                66.
              </h3>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <HelpOutlineOutlinedIcon sx={{ marginRight: "0.5rem" }} />
              <h3 className="menu-button-text m-0">Aide</h3>
            </AccordionSummary>
            <AccordionDetails>
              <h3 className="text-body">
                Pour toute question, veuillez nous contacter par email à
                l'adresse suivante:
                <br />
                support@icebergtech.net ou par <br /> téléphone au 06 66 66 66
                66.
              </h3>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </>
  );
};
