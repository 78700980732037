import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import useAxios from "../../../hooks/useAxios";
import axios from "../../../apis/my_axios";
import Skeleton from "@mui/material/Skeleton";
// import shoppingVideo from "../../../assets/videos/shopping.mp4";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import googlePlayIcon from "../../../assets/pngs/google_play.png";
import PlayStoreIcon from "../../../assets/pngs/play__store.png";

import "./styles.css";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper";
import { MenyType, NewMenuModel } from "../../../types/menuTypes/menuTypes";

const Carousel = () => {
  // const [data, error, loading] = useAxios({
  //   axiosInstance: axios,
  //   method: "GET",
  //   endpoint: "/api/menu/",
  // });

  // const [data2] = useAxios({
  //   axiosInstance: axios,
  //   method: "GET",
  //   endpoint: "/api/nouvelle-collection/actif/",
  // });

  // const CastedMenuData = data as unknown as MenyType[];
  // let NewCastMenuData = data2 as unknown as NewMenuModel;

  return (
    <div style={{ width: "100%", height: "80vh", position: "relative" }}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {/* <SwiperSlide>
          <video
            loop
            autoPlay
            muted
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          >
            <source
              src="../../../assets/videos/shopping.mp4"
              type="video/mp4"
            />
          </video>
        </SwiperSlide> */}
        <SwiperSlide>
          <img
            src="https://cdn.pixabay.com/photo/2018/12/10/21/09/store-3867742_960_720.jpg"
            alt="3867742_960_720"
            key="3867742_960_720"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://cdn.pixabay.com/photo/2021/05/24/12/46/kids-6279080_960_720.jpg"
            alt="6279080_960_720"
            key="6279080_960_720"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://cdn.pixabay.com/photo/2017/09/19/21/37/fashion-2766734_960_720.jpg"
            alt="fashion-2766734_960_720"
            key="fashion-2766734_960_720"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://cdn.pixabay.com/photo/2017/06/20/22/14/man-2425121_960_720.jpg"
            alt="man-2425121_960_720"
            key="man-2425121_960_720"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://cdn.pixabay.com/photo/2016/12/12/20/34/shirt-1902601_960_720.jpg"
            alt="1902601_960_720"
            key="1902601_960_720"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://cdn.pixabay.com/photo/2018/12/10/21/33/winter-boots-3867775_960_720.jpg"
            alt="boots-3867775_960_720"
            key="boots-3867775_960_720"
          />
        </SwiperSlide>
        {/* {data && !loading && !error && NewCastMenuData && (
          <SwiperSlide>
            <img src={NewCastMenuData.picture} alt={NewCastMenuData.nom} />
          </SwiperSlide>
        )} */}
        {/* {loading ? (
          <div>
            <SwiperSlide>
              <Skeleton variant="rectangular" animation="wave" width="100%">
                <div style={{ paddingTop: "57%" }} />
              </Skeleton>
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton variant="rectangular" animation="wave" width="100%">
                <div style={{ paddingTop: "57%" }} />
              </Skeleton>
            </SwiperSlide>
          </div>
        ) : error ? (
          <SwiperSlide>
            <Skeleton
              sx={{ height: 190 }}
              animation="wave"
              variant="rectangular"
            />
          </SwiperSlide>
        ) : (
          CastedMenuData &&
          CastedMenuData.map((item) => {
            return (
              <SwiperSlide>
                <img src={item.photo_principal} alt={item.nom} />
              </SwiperSlide>
            );
          })
        )} */}
      </Swiper>
    </div>
  );
};

export default Carousel;
