import React, { createContext, useContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { isPromoisNewModel } from "../types/IsPromoIsNew/isPromoIsNewModel";
import { SearchModel } from "../types/SearchModels/SearchModel";
const Context = createContext<any>(null);

export const StateContext = ({ children }: any) => {
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [childList, setChildList] = useState<[] | null>(null);
  let [qtty, setQtty] = useState(1);
  const [openSearchModal, setOpenSearchModal] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [promoCodeName, setPromoCodeName] = React.useState<string | null>(null);
  const [isPromoIsNew, setIsPromoIsNew] = React.useState<isPromoisNewModel>({
    isPromo: "",
    isNew: "",
  });

  const incQty = () => {
    setQtty(qtty + 1);
  };
  const decQty = () => {
    if (qtty > 1) {
      setQtty(qtty - 1);
    } else {
      toast.error("You can't have less than 1 quantity");
    }
  };

  return (
    <Context.Provider
      value={{
        showCart,
        setShowCart,
        cartItems,
        setCartItems,
        totalPrice,
        setTotalPrice,
        totalQuantity,
        setTotalQuantity,
        qtty,
        setQtty,
        incQty,
        decQty,
        userInfo,
        setUserInfo,
        childList,
        setChildList,
        openSearchModal,
        setOpenSearchModal,
        searchQuery,
        setSearchQuery,
        isPromoIsNew,
        setIsPromoIsNew,
        promoCodeName,
        setPromoCodeName,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
