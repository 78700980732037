import { makeStyles, alpha } from "@material-ui/core";

export default makeStyles((theme) => ({
  footer__container: {
    backgroundColor: "black",
    width: "100%",
  },
  footer__content: {
    display: "flex",
    flexDirection: "column",
    pading: "0 20px",
    alignItems: "start",
    justifyContent: "center",
    fontColor: "white",
    color: "white",
    // direction: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "start",
      padding: "10px 50px",
    },
  },

  footer__content__title: {
    paddingTop: "6px",
    color: "#64C7EB",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 0 0 0",
    },
  },

  footer__content__container: {
    display: "flex",

    flexDirection: "column",
    gap: "1rem",
    padding: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 1px",
    },
  },

  social__icons__container: {
    // animation from bottom to top using keyframes

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // width: "100%",
    paddingTop: "1rem",

    height: "10px",
    gap: "0.5rem",

    [theme.breakpoints.down("sm")]: {
      paddingPottom: "3rem",
    },
  },

  social__facebook__icon: {
    cursor: "pointer",
    "&:hover": {
      color: "#3b5998",
      textDecoration: "underline",
      transition: "all 0.3s ease-in-out",
      scale: "1.2",
      animation: "$hover 0.3s ease-in-out",
    },
  },

  social__instagram__icon: {
    cursor: "pointer",
    "&:hover": {
      color: "#e4405f",
      textDecoration: "underline",
      transition: "all 0.3s ease-in-out",
      scale: "1.2",
      animation: "$hover 0.3s ease-in-out",
    },
  },

  social__apple__icon: {
    cursor: "pointer",
    "&:hover": {
      color: "gray",
      textDecoration: "underline",
      transition: "all 0.3s ease-in-out",
      animation: "$hover 0.3s ease-in-out",
      scale: "1.2",
    },
  },

  social__google__icon: {
    cursor: "pointer",
    "&:hover": {
      color: "#3EB7C0",
      textDecoration: "underline",
      transition: "all 0.3s ease-in-out",
      animation: "$hover 0.3s ease-in-out",
      scale: "1.2",
    },
  },

  footer__links: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    itemAlign: "start",

    //li
    "& span": {
      listStyle: "none",
      cursor: "pointer",

      "&:hover": {
        color: "red",
        textDecoration: "underline",
        transition: "all 0.3s ease-in-out",
        animation: "$hover 0.3s ease-in-out",
      },
    },
  },

  footer__links__icon: {
    cursor: "pointer",
    color: "white",
    margin: 0,
    padding: 0,
    "&:hover": {
      color: "green",
      textDecoration: "underline",
      transition: "all 0.3s ease-in-out",
      animation: "$hover 0.3s ease-in-out",
    },
  },

  footer__links__text: {
    color: "white",
  },

  footer__contact__container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "start",
    gap: "1rem",
    margin: 0,
    padding: 0,
  },

  footer__divider: {
    backgroundColor: "white",
    width: "60%",
    height: "1px",
    // center the divider in the middle
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },

  footer__bottom__container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "1rem",
    margin: "0.5rem 0",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 30px",
      textAlign: "center",
    },
  },

  footer__bottom__text: {
    color: "white",
    // a styling
    "& a": {
      color: "#64C7EB",
      textDecoration: "none",
      "&:hover": {
        color: "red",
        textDecoration: "underline",
        transition: "all 0.3s ease-in-out",
        animation: "$hover 0.3s ease-in-out",
      },
    },
  },
}));
