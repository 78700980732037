import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  title: {
    width: "60%",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "3.2px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
      fontSize: ".2px",
      color: "black",
    },
  },
  productsCardsContainer: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexWrap: "wrap",
    gap: "18px",
  },
  cardRoot: {
    width: "322px",
    height: "510px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));
