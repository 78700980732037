import React from "react";
import { CommandesContainer } from "./CommandesContainer";
import { useEffect } from "react";
import { userInfoType } from "../../types/user/user";
import { CommandesModel } from "../../types/Commandes/CommandesModel";
import Typography from "@mui/material/Typography";
import { PermissionModel } from "../../types/user/permisionModel";
import useStyles from "./styles";
import { LoadingProductCardContainer } from "./LoadingProductCardContainer";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";

export const Commandes = () => {
  // const [commandes, setCommandes] = React.useState<CommandesModel | null>(null);
  // const [isAdmin, setIsAdmin] = React.useState(false);
  const classes = useStyles();

  const {
    data: commandesData,
    error: commandesError,
    isLoading: commandesIsLoading,
    isError: commandesIsError,
    isSuccess: commandesIsSuccess,
    refetch,
  }: UseQueryResult<CommandesModel, Error> = useQuery<CommandesModel, Error>(
    "commandes",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/commande/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60, //  1 hour
    }
  );

  const {
    data: userPermissionsData,
    error: userPermissionsError,
    isLoading: userPermissionsIsLoading,
    isError: userPermissionsIsError,
    isSuccess: userPermissionsIsSuccess,
    refetch: refetchUserPermissions,
  }: UseQueryResult<PermissionModel[], Error> = useQuery<
    PermissionModel[],
    Error
  >(
    "userPermissions",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/permission/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
    }
  );

  return (
    <div className={classes.commandesRoot}>
      {commandesIsSuccess && commandesData.results.length === 0 && (
        <Typography variant="h5" align="center" sx={{ marginTop: "14%" }}>
          Aucune commande n'a été passée
        </Typography>
      )}
      {commandesIsSuccess ? (
        commandesData.results.map((commande) => (
          <CommandesContainer
            commandesResults={commande}
            isAdmin={userPermissionsIsSuccess && userPermissionsData.length > 0}
            key={commande.id}
          />
        ))
      ) : (
        <LoadingProductCardContainer />
      )}
    </div>
  );
};
