import React from "react";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Commandepca } from "../../types/Commandes/CommandesModel";
import useStyles from "./styles";

type ProductCardContentProps = {
  commande: Commandepca;
};

export const ProductCardContent = (props: ProductCardContentProps) => {
  const classes = useStyles();
  const { commande } = props;

  return (
    <div>
      <CardContent className={classes.card__content}>
        <CardMedia
          component="img"
          // height="194"
          image={commande.produitcouleurattribut.photo_produit}
          alt={commande.produitcouleurattribut.nom_produit}
          sx={{
            width: "180px",
            height: "auto",
            xs: {
              width: "100%",
            },
          }}
        />
        <Box>
          <Typography gutterBottom variant="h5" component="h2">
            {commande.produitcouleurattribut.nom_produit}
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            sx={{
              fontSize: "1rem",
              color: "gray",
            }}
          >
            {commande.produitcouleurattribut.type_attribut} :{" "}
            {commande.produitcouleurattribut.code_attribut}
          </Typography>

          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            sx={{
              fontSize: "1rem",
              color: "gray",
            }}
          ></Typography>
        </Box>
      </CardContent>
    </div>
  );
};
