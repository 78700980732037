import React from "react";
import { MenuChildren } from "../../types/menuTypes/menuTypes";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { LastChildModel } from "../../types/LastChild/lastChild";
import { useStateContext } from "../../context/stateContext";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IsNewIsPromoModel } from "../../types/IsNewIsPromoModel/IsNewIsPromoModel";

type Props = {
  child: MenuChildren;
  // handleClose: () => void;
  setDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  dropdown: boolean;
  parentId: number;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LastChild = (props: Props) => {
  const { child, setDropdown, dropdown, parentId, setOpenMenu } = props;
  const { childList, setChildList } = useStateContext();
  const navigate = useNavigate();
  const [lastChild, setLastChild] = React.useState<LastChildModel[] | null>(
    null
  );
  const classes = useStyles();
  const { isPromoIsNew, setIsPromoIsNew } = useStateContext();

  const {
    data: isNouvelleCollection_isPromotion,
    status: statusNouvelleCollection_isPromotion,
    refetch: refetchNouvelleCollection_isPromotion,
    isFetching: isFetchingNouvelleCollection_isPromotion,
    isLoading: isLoadingNouvelleCollection_isPromotion,
  } = useQuery<IsNewIsPromoModel, Error, IsNewIsPromoModel, string>(
    `isNewIsPromo${child.id}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/menu/${child.id}/is_new_is_promotion/`
      );
      return response.data;
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
      // enabled:
      // child.id !== "new" &&
      // child.id !== "promotion" &&
      //   statusLastChild === "success",
      onSuccess: (data) => {
        console.log("IsNewIsPromo MENU ---> ", data);
      },
      onError: (error) => {
        console.log("IsNewIsPromo ---> ", error);
      },
    }
  );

  const { data, status }: UseQueryResult<LastChildModel[], Error> = useQuery<
    LastChildModel[],
    Error,
    LastChildModel[],
    string
  >(
    `lastChild${child.id}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/menu/${child.id}/last_childs/`
      );
      return [
        {
          id: parentId,
          nom: "Voir tout",
          photo_principal: "",
          photo_secondaire: "",
        },
        ...response.data,
      ];
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  return (
    <div>
      {status === "loading" ? (
        <Box sx={{ width: 100 }}>
          <Skeleton animation="wave" style={{ marginTop: 6 }} />
          <Skeleton animation="wave" style={{ marginTop: 6 }} />
          <Skeleton animation="wave" style={{ marginTop: 6 }} />
        </Box>
      ) : status === "error" ? (
        <Typography></Typography>
      ) : status === "success" && data ? (
        <div className="last_child_contian">
          {data?.length > 0 &&
            data.map(({ id, nom }) => (
              <Link
                key={id}
                style={{
                  fontWeight: "600",
                }}
                className={
                  window.location.pathname.includes(id.toString())
                    ? classes.menu__link__active
                    : classes.menu__link
                }
                to={`collections/${id}/${child.id}`}
              >
                <h3
                  className="dropdown-link-text"
                  onClick={() => {
                    document.body.scrollTop = 0;
                    setChildList([child.id, id]);
                    setDropdown(false);
                    setOpenMenu(false);
                    setIsPromoIsNew({
                      isPromo: "",
                      isNew: "",
                    });
                  }}
                  key={nom}
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {nom}
                </h3>
              </Link>
            ))}
          {statusNouvelleCollection_isPromotion === "success" &&
            isNouvelleCollection_isPromotion.is_promotion && (
              <div
                onClick={() => {
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                  setIsPromoIsNew({
                    isPromo: "t",
                    isNew: "",
                  });
                  navigate(`/collections/${child.id}/${child.id}`);
                }}
                // key={id}
              >
                <span
                  className="dropdown-link-text"
                  style={{
                    color: "#E46BB2",
                    cursor: "pointer",
                  }}
                >
                  {"Promotions"}
                </span>
              </div>
            )}
          {statusNouvelleCollection_isPromotion === "success" &&
            isNouvelleCollection_isPromotion.is_new && (
              <div
                onClick={() => {
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                  setIsPromoIsNew({
                    isPromo: "",
                    isNew: "t",
                  });

                  navigate(`/collections/${child.id}/${child.id}`);
                }}
                // key={id}
              >
                <span
                  className="dropdown-link-text"
                  style={{
                    color: "#0E8161",

                    cursor: "pointer",
                  }}
                >
                  {"Nouveautés"}
                </span>
              </div>
            )}
        </div>
      ) : null}
    </div>
  );
};
