import React, { useEffect, useRef } from "react";

import Card from "@mui/material/Card";
import { useStateContext } from "../../context/stateContext";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";

import Stack from "@mui/material/Stack";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { CartCard } from "./cartCard";
import { useDispatch } from "react-redux";
import { CardModel } from "../../types/CardModels/CardModel";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { userInfoType } from "../../types/user/user";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { UserModel } from "../../types/UserModels/UserModel";
import { GetPromoCodeModel } from "../../types/PromoCode/getPromoCode";
import { ReductionComponent } from "../Basket/reductionComponent";
import { CheckPromoCodeComponent } from "../Basket/checkPromoCode";

export const Cart = () => {
  const {
    totalQuantity,
    showCart,
    setShowCart,
    setTotalQuantity,
    qtty,
    setQtty,
    promoCodeName,
    setPromoCodeName,
  } = useStateContext();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = React.useState<UserModel | null>(null);
  const [youHaveAPromoCode, setYouHaveAPromoCode] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState("");

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    refetch,
  }: UseQueryResult<CardModel[], Error> = useQuery<CardModel[], Error>(
    "cart",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/panier/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      onSuccess: (data) => {
        console.log("data is ", data);
        setTotalQuantity(data!.length);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [showCart, setTotalQuantity, totalQuantity, qtty]);

  let cartRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const nUserInfo = JSON.parse(localStorage.getItem("profile")!);
    setUserInfo(nUserInfo);

    let handler = (event: any) => {
      if (!cartRef.current?.contains(event.target as Node)) {
        // document.body.style.overflow = "auto";
        setShowCart(false);
      }
      if (event.key === "Escape") {
        // document.body.style.overflow = "auto";
        setShowCart(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleCommandeClick = () => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     authorization: `Bearer ${convertedUserInfo.token}`,
    //   },
    //   body: JSON.stringify({
    //     codepromo: "",
    //   }),
    // };
    // fetch(
    //   process.env.REACT_APP_API_URL + "/api/panier/valider/", // google_sign_in_token,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.detail === "Adresse manquante") {
    //       toast.error("ajouter une adresse avant de valider la commande svp");
    //       navigate("profile_page");
    //     } else {
    //       dispatch({ type: "CLEAR_CART" });
    //       setQtty(0);
    //       setTotalQuantity(0);
    //       setShowCart(false);
    //       toast.success("Votre panier a été mis à jour");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error is ", error);
    //   })
    //   .then(() => {
    //     setShowCart(false);
    //   });
    if (userData?.address) {
      navigate("basket");
      toast.success("Votre panier a été mis à jour cliquez sur valider");
    } else {
      navigate("profile_page/add_product");
      toast.error("ajouter une adresse avant de valider la commande svp");
    }

    setShowCart(false);
  };

  const calculateTotal = () => {
    let total = 0;
    data?.forEach((item: CardModel) => {
      total += item.quantite * item.produitcouleur.prix;
    });
    return total;
  };

  const {
    data: userData,
    isSuccess: isSuccessUserData,
    refetch: refetchUserData,
    status: statusUserData,
  }: UseQueryResult<UserModel, Error> = useQuery<UserModel, Error>(
    `userData-${userInfo?.id}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/me/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      //   enabled: !!userInfo?.id,
      onSuccess: (data) => {
        console.log("userdata success is ", data);
        setUserInfo(data);
      },
      onError: (error) => {
        console.log("userdata error is ", error);
      },
    }
  );

  const {
    data: getPromoCodeData,
    isSuccess: isSuccessGetPromoCode,
    refetch: refetchGetPromoCode,
    status: statusGetPromoCode,
  }: UseQueryResult<GetPromoCodeModel, Error> = useQuery<
    GetPromoCodeModel,
    Error
  >(
    "getPromoCode",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/panier/get_code_promo`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 5, // 5 second
      cacheTime: 1000 * 5, // 5 second
      onSuccess: (data) => {
        console.log("getPromoCodeData22 success is ", data);
        data.code && setPromoCodeName(data.code);
      },
      onError: (error) => {
        console.log("getPromoCodeData22 error is ", error);
      },
    }
  );

  return (
    <div
      style={{
        display: showCart ? "block" : "none",
      }}
      className="cart__container"
    >
      <Card
        className={classes.cart__root}
        elevation={0}
        ref={cartRef as React.RefObject<HTMLDivElement>}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: "300", margin: "1rem", color: "red" }}
          >
            ({totalQuantity} {totalQuantity > 1 ? "articles" : "article"})
          </Typography>
          <Button
            onClick={() => {
              setShowCart(false);
              // document.body.style.overflow = "auto";
            }}
            style={{ color: "black" }}
          >
            <Typography className={classes.cart__return__text}>
              continuer l'achat
            </Typography>
            <KeyboardArrowRightRoundedIcon />
          </Button>
        </div>
        {totalQuantity > 0 && (
          <div
            style={{
              maxHeight: "90vh",
              overflow: "auto",
            }}
          >
            {data?.map((card) => (
              <CartCard key={card.id} card={card} />
            ))}
            <div className={classes.cart__total__container}>
              {/* <Typography variant="h6" className="text-tertiary-dark font-body">
                Total de la commande : {calculateTotal()} DH{" "}
              </Typography>
              {userData?.address ? (
                <>
                  <Typography variant="h6" className="text-subtitle">
                    + Frais de livraison: {userData?.address?.frais_livraison}{" "}
                    DH
                  </Typography>
                  <Typography
                    variant="h6"
                    className="text-primary-dark fs-xxxl"
                  >
                    Total à payer:{" "}
                    {calculateTotal() + +userData?.address?.frais_livraison!} DH
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" className="text-subtitle">
                    + Frais de livraison
                  </Typography>
                </>
              )} */}

              {isSuccessGetPromoCode &&
              getPromoCodeData?.code &&
              promoCodeName ? (
                ReductionComponent({
                  getPromoCodeData,
                  setPromoCodeName,
                })
              ) : (
                <Typography
                  variant="h6"
                  className={classes.promo__code__text}
                  onClick={() => setYouHaveAPromoCode(!youHaveAPromoCode)}
                >
                  Tu as un code promo ?
                </Typography>
              )}

              {youHaveAPromoCode &&
                CheckPromoCodeComponent({
                  promoCode: promoCode,
                  setPromoCode: setPromoCode,
                  setPromoCodeName: setPromoCodeName,
                  setYouHaveAPromoCode: setYouHaveAPromoCode,
                })}

              <Typography variant="h6" className="text-tertiary-dark font-body">
                Total de la commande :{" "}
                {getPromoCodeData?.code && promoCodeName
                  ? getPromoCodeData.prix_total
                  : calculateTotal()}{" "}
                DH
              </Typography>
              <Typography variant="h6" className="text-subtitle">
                + Frais de livraison: {userData?.address.frais_livraison} DH
              </Typography>
              <Typography variant="h6" className="text-primary-dark fs-xxxl">
                Total à payer:{" "}
                {(getPromoCodeData?.code && promoCodeName
                  ? getPromoCodeData.prix_total
                  : calculateTotal()) +
                  +userData?.address.frais_livraison!}{" "}
                DH
              </Typography>
            </div>
            <Stack
              direction="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                onClick={() => handleCommandeClick()}
                style={{
                  margin: "1rem",
                  color: "white",
                  backgroundColor: "black",
                }}
              >
                <AddShoppingCartIcon />
                <Typography
                  className={classes.cart__commande__text}
                  variant="h6"
                >
                  {"commancer l'achat ("}
                  {totalQuantity} {")"}
                </Typography>
              </Button>
            </Stack>
          </div>
        )}

        {totalQuantity === 0 && (
          <div
            style={{
              textAlign: "center",
              margin: "1rem",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ShoppingBagRoundedIcon style={{ fontSize: "5rem" }} />
            <Typography variant="h5" style={{ fontWeight: "300" }}>
              Votre panier est vide
            </Typography>
            <Button
              variant="contained"
              style={{
                backgroundColor: "black",
                color: "white",
                margin: "1rem",
                marginTop: "2rem",
                width: "85%",
                // borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/");
                setShowCart(false);
              }}
            >
              <Typography variant="h5" style={{ fontWeight: "300" }}>
                Continuer mes achats
              </Typography>
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};
