const CardReducer = (state = [], action: any) => {
  switch (action.type) {
    case "FETCH_ALL_CARDS":
      return action.payload;
    case "ADD_CARD":
      return state;
    default:
      return state;
  }
};

export default CardReducer;
