import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ProductCardContent } from "./ProductCardContent";
import { CommandesResultModel } from "../../types/Commandes/CommandesModel";
import Divider from "@mui/material/Divider";
import { userInfoType } from "../../types/user/user";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import useStyles from "./styles";

type CommandesContainerProps = {
  commandesResults: CommandesResultModel;
  isAdmin: boolean;
};

export const CommandesContainer = (props: CommandesContainerProps) => {
  const { commandesResults, isAdmin } = props;
  // prochain_statuts_commande
  const [prochainStatutsCommande, setProchainStatutsCommande] = React.useState<
    String[] | null
  >(null);
  const classes = useStyles();

  useEffect(() => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/api/commande/${commandesResults.id}/prochain_statuts_commande/`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setProchainStatutsCommande(data as string[]);
      })
      .catch((error) => {
        console.log("error is ", error);
      });
    // https://petshop.icebergtech.net/api/commande/$id/valider/
  }, []);

  const handleValiderCommande = () => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/api/commande/${commandesResults.id}/valider/`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        toast.success("Commande validée avec succès");
      })
      .catch((error) => {
        toast.error("Erreur lors de la validation de la commande");
      })
      .then(() => {
        window.location.reload();
      });
  };

  // https://petshop.icebergtech.net/api/commande/$id/livrer/
  const handleLivrerCommande = () => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/api/commande/${commandesResults.id}/livrer/`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        toast.success("Commande livrée avec succès");
      })
      .catch((error) => {
        toast.error("Erreur lors de la livraison de la commande");
      })
      .then(() => {
        window.location.reload();
      });
  };

  const handlerejeterCommande = () => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        authorization: `Bearer ${convertedUserInfo.token}`,
      },
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/api/commande/${commandesResults.id}/rejeter/`, // google_sign_in_token,

      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        toast.success("Commande rejetée avec succès");
      })
      .catch((error) => {
        toast.error("Erreur lors de la rejet de la commande");
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <Card className={classes.commandeContainerCard}>
      <Box className={classes.commandeContainerBox}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          sx={{
            fontSize: "1rem",
          }}
        >
          Prix total:
          <span> {commandesResults.prix_total_sans_code_promo}</span>
          <span> MAD</span>
        </Typography>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          sx={{
            color: commandesResults.status === "VALIDATED" ? "green" : "gray",
          }}
        >
          {commandesResults.status}
        </Typography>
      </Box>
      <Divider variant="middle" />
      {commandesResults &&
        commandesResults.commandepcas.map((commande) => (
          <ProductCardContent commande={commande} key={commande.id} />
        ))}
      <Stack direction="row" className={classes.commandeContainerStackButton}>
        {prochainStatutsCommande &&
          prochainStatutsCommande.map((statut) => (
            <Button
              onClick={() => {
                if (statut === "VALIDATED") {
                  handleValiderCommande();
                } else if (statut === "REJECTED") {
                  handlerejeterCommande();
                } else if (statut === "DELIVERED") {
                  handleLivrerCommande();
                }
              }}
              sx={{
                color: "white",
                backgroundColor: "black",
              }}
              variant="contained"
            >
              {statut}
            </Button>
          ))}
      </Stack>
    </Card>
  );
};
