import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "22px",
    columnGap: "22px",
  },

  circleBackground: {
    borderRadius: "50%",
    width: "2rem",
    height: "2rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
  },

  filterMobile: {
    margin: "0 3px",
  },
}));
