import React from "react";
import CollectionsProductCard from "./CollectionsProductCard";
import Stack from "@mui/material/Stack";
import { DescriptionSection } from "./DescriptionSection";
import { useEffect, useState } from "react";
import {
  ProductsListModel,
  ProductModel,
} from "../../../types/productsList/productsLIst";
import { FilterCategoryGroupModelList } from "../../../types/FilterModels/FilterModel";
import useStyles from "./styles";
import { LodingCardsList } from "./LodingCardsList";
import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useStateContext } from "../../../context/stateContext";

interface Props {
  listId: string;
  selectedFilter: FilterCategoryGroupModelList;
  filterColor: string[];
  setTrierPar: React.Dispatch<React.SetStateAction<string>>;
  treirPar: string;
}

const CollectionsProductsList = ({
  listId,
  selectedFilter,
  filterColor,
  setTrierPar,
  treirPar,
}: Props) => {
  const { id: userId, childId } = useParams();
  const { isPromoIsNew } = useStateContext();
  let filterList = "&attributs=";
  const classes = useStyles();

  selectedFilter.forEach((filter) => {
    filter.items.forEach((item) => {
      filterList += `${item}-`;
    });
    filterList =
      filterList.slice(-1) === "-" ? filterList.slice(0, -1) : filterList;
    filterList += ",";
  });
  filterList =
    filterList.slice(-1) === "," ? filterList.slice(0, -1) : filterList;
  filterList =
    filterList.slice(11) === "," ? filterList.slice(0, 11) : filterList;
  filterList =
    filterList.slice(11) === "-" ? filterList.slice(0, 11) : filterList;

  const {
    data: productsList,
    status,
    refetch,
    isFetching,
    isLoading,
  }: UseQueryResult<ProductsListModel, Error> = useQuery<
    ProductsListModel,
    Error,
    ProductsListModel,
    string
  >(
    `${listId} ${selectedFilter} ${filterColor} ${treirPar}`,
    async () => {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/produit?categorie=${listId}${filterList}&couleurs=${filterColor.join(
          "-"
        )}&order_by=${treirPar}&is_new=${isPromoIsNew.isNew}&is_promotion=${
          isPromoIsNew.isPromo
        }`
      );
      return response.data;
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  useEffect(() => {
    refetch();
  }, [listId, selectedFilter, filterColor, treirPar, isPromoIsNew]);

  if (isFetching || isLoading) {
    return <LodingCardsList />;
  }

  if (status === "error") {
    return <div className="error_container">Quelque chose s'est mal passé</div>;
  }

  return (
    <>
      {status === "success" && (
        <Stack spacing={2} direction="column">
          <Stack direction="row" className={classes.root}>
            {productsList.results.map((product, index) => (
              <CollectionsProductCard key={index} {...product} />
            ))}
          </Stack>
          {/* <DescriptionSection /> */}
        </Stack>
      )}
    </>
  );
};

export default CollectionsProductsList;
