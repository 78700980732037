import React from "react";

export const TermsOfUse = () => {
  return (
    <div style={{ width: "100vw" }}>
      <h1 className="text-center footer-title" style={{ marginTop: "2rem" }}>
        Conditions d'utilisation
      </h1>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="terms-of-use">
              <p>
                <strong>Conditions d'utilisation</strong>
              </p>
              <p>
                <strong>1. Conditions d'utilisation</strong>
              </p>
              <p>
                En accédant à ce site Web, vous acceptez d’être lié par ces
                conditions d’utilisation, toutes les lois et réglementations
                applicables et convenez d’en être responsable. Si vous n’êtes
                pas d’accord avec l’un de ces termes, vous n’êtes pas autorisé à
                utiliser ou accéder à ce site. Les matériaux contenus sur ce
                site sont protégés par les lois sur le droit d’auteur et les
                marques commerciales applicables.
              </p>
              <p>
                <strong>2. Utilisation de licence</strong>
              </p>
              <p>
                Nous vous accordons une licence limitée pour accéder et utiliser
                personnellement ce site et non pour le télécharger (à
                l’exception du code source et des fichiers de données) ou le
                modifier. Cette licence ne comprend pas l’utilisation de
                données, de robots, de minage de données, d’outils de collecte
                et d’extraction de données, d’outils de collecte et d’extraction
                de données, d’outils de collecte et d’extraction de données,
                d’outils de collecte et d’extraction de données, d’outils de
                collecte et d’extraction de données, d’outils de collecte et
                d’extraction de données, d’outils de collecte et d’extraction de
                données,
              </p>
              <p>
                <strong>3. Limitation de responsabilité</strong>
              </p>
              <p>
                Dans la mesure permise par la loi en vigueur, nous ne serons pas
                responsables des dommages directs, indirects, spéciaux,
                consécutifs ou accessoires découlant de l’utilisation de ce site
                ou de tout autre site Web auquel vous accédez à partir de ce
                site. Nous ne garantissons pas l’exactitude ou l’exhaustivité
                des informations et des matériaux disponibles sur ce site. Nous
                ne garantissons pas que le site sera disponible sans
                interruption ou sans erreur. Nous ne serons pas responsables de
                toute perte ou dommage résultant de l’utilisation de ce site.
                Les informations et les matériaux disponibles sur ce site sont
                fournis « tels quels » sans aucune garantie de quelque nature
                que ce soit, expresse ou implicite, y compris, mais sans s’y
                limiter, les garanties implicites de qualité marchande,
                d’adéquation à un usage particulier, de non-contrefaçon, de
                titre et d’absence d’erreurs. Nous ne garantissons pas que les
                résultats qui peuvent être obtenus de l’utilisation des
                informations et des matériaux disponibles sur ce site seront
                exacts ou fiables. Vous acceptez d’utiliser ce site à vos
                propres risques. Si vous n’êtes pas satisfait de ce site, votre
                unique recours est d’arrêter d’utiliser ce site.
              </p>

              <p>
                <strong>4. Modifications</strong>
              </p>
              <p>
                Nous pouvons modifier ces conditions d’utilisation à tout moment
                sans préavis en publiant les modifications sur ce site. Vous
                êtes tenu de vérifier ces conditions d’utilisation de temps en
                temps pour vous assurer que vous êtes d’accord avec les
                modifications. En utilisant ce site après que nous ayons publié
                des modifications, vous acceptez d’être lié par ces
                modifications.
              </p>
              <p>
                <strong>5. Liens</strong>
              </p>
              <p>
                Ce site peut contenir des liens vers d’autres sites qui ne sont
                pas exploités par nous. Nous ne sommes pas responsables du
                contenu de ces sites. Nous vous recommandons de lire les
                conditions d’utilisation et les politiques de confidentialité de
                chaque site que vous visitez.
              </p>
              <p>
                <strong>6. Indemnisation</strong>
              </p>
              <p>
                Vous acceptez de défendre, de dédommager et de indemniser notre
                société, nos dirigeants, nos employés, nos agents, nos
                fournisseurs, nos partenaires et nos sous-traitants de toute
                réclamation, perte, responsabilité, dommage (y compris les frais
                raisonnables de défense et les honoraires d’avocat) ou toute
                autre responsabilité découlant de ou en relation avec votre
                utilisation de ce site, votre violation de ces conditions
                d’utilisation ou de toute loi ou règlement applicable, ou toute
                réclamation que l’un de vos utilisateurs fait contre nous.
              </p>
              <p>
                <strong>7. Loi applicable</strong>
              </p>
              <p>
                Ces conditions d’utilisation et tout différend ou litige qui
                pourrait découler de leur utilisation ou de leur interprétation
                par les parties sont régis par les lois de l’État de Californie,
                sans égard à ses conflits de lois. Vous acceptez de vous
                soumettre à la compétence exclusive des tribunaux de l’État de
                Californie pour tout litige ou différend découlant de ces
                conditions d’utilisation ou de leur utilisation.
              </p>
              <p>
                <strong>8. Divisibilité</strong>
              </p>
              <p>
                Si une disposition de ces conditions d’utilisation est jugée
                invalide ou inapplicable par un tribunal, cette disposition sera
                réputée séparable de ces conditions d’utilisation et n’affectera
                pas la validité et l’applicabilité des autres dispositions.
              </p>
              <p>
                <strong>9. Délai</strong>
              </p>
              <p>
                Nous ne serons pas responsables des retards ou des manquements à
                l’exécution de nos obligations découlant de ces conditions d
              </p>
              <p>
                <strong>10. Contactez-nous</strong>
              </p>
              <p>
                Si vous avez des questions sur ces conditions d’utilisation,
                veuillez nous contacter à l’adresse suivante:
              </p>
              <p>
                <a href="mailto: contact@icebergtech.net">
                  contact@icebergtech.net
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
