import React from "react";
import Box from "@mui/material/Box";
import { Card, CardMedia, Typography, Divider } from "@material-ui/core";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

interface props {
  image: string;
  title: string;
  id: number;
}

export const AccessoriesAndMoreSectionCard = (props: props) => {
  const { image, title, id } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Card style={{ height: "100%", width: "100%", backgroundColor: "red" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <div
          style={{
            position: "relative",
            // backgroundColor: "green",
            height: "100%",
            width: "100%",
          }}
          // onClick={() => {
          //   navigate(`/product_details/${id}`);
          //   window.scrollTo(0, 0);
          // }}
        >
          <CardMedia
            style={{ height: "100%", width: "100%" }}
            component="img"
            image={image}
          />
          <Box
            style={{
              position: "absolute",
              color: "white",
              bottom: "2%",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
            }}
          >
            <Typography variant="h2" className={classes.title}>
              <h3 className="big-text">{title}</h3>
            </Typography>
            <Divider className={classes.divider} />
          </Box>
        </div>
      </div>
    </Card>
  );
};
