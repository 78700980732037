import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";
import { useNavigate } from "react-router-dom";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Divider from "@mui/material/Divider";

export default function Footer() {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.footer__container}>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: "1470px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingY: 2,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={3} className={classes.footer__content}>
            <div className={classes.footer__content__container}>
              <h4 className="footer-title">ICEBERG TECH STORE</h4>
              <p className="footer__content__text">
                iceberg store est une solution de vente en ligne de produits
                permettant de mettre en avant ces derniers, à travers une
                catégorisation simplifiée couvrant des critères de choix
                paramétrables.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className={classes.footer__content}>
            <div className={classes.footer__content__container}>
              <h4 className="footer-title">NOUS SUIVRE</h4>
              <div className={classes.social__icons__container}>
                <FacebookIcon className={classes.social__facebook__icon} />
                <InstagramIcon className={classes.social__instagram__icon} />
                <AppleIcon className={classes.social__apple__icon} />
                <ShopIcon className={classes.social__google__icon} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className={classes.footer__content}>
            <div className={classes.footer__content__container}>
              <h4 className="footer-title">SUR NOTRE STORE</h4>
              <div className={classes.footer__links}>
                <h5
                  className="footer__content__link__title"
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  Accueil
                </h5>
                {/* <span>Produits</span> */}
                <h5
                  className="footer__content__link__title"
                  onClick={() => {
                    navigate("/products");
                    window.scrollTo(0, 0);
                  }}
                >
                  Produits
                </h5>
                {/* <span>Blog</span> */}
                <h5
                  className="footer__content__link__title"
                  onClick={() => {
                    navigate("/blog");
                    window.scrollTo(0, 0);
                  }}
                >
                  Blog
                </h5>
                {/* <span>À propos</span> */}
                <h5
                  className="footer__content__link__title"
                  onClick={() => {
                    navigate("/about");
                    window.scrollTo(0, 0);
                  }}
                >
                  À propos
                </h5>
                {/* <span>Contact</span> */}
                <h5
                  className="footer__content__link__title"
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo(0, 0);
                  }}
                >
                  Contact
                </h5>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.footer__content}>
            <div className={classes.footer__content__container}>
              <h4 className="footer-title">INFORMATION</h4>
              <div className={classes.footer__links}>
                <h5
                  className="footer__content__link__title"
                  onClick={() => {
                    navigate("privacy_policy");
                    window.scrollTo(0, 0);
                  }}
                >
                  Politique de confidentialité
                </h5>
                {/* <span>Conditions d'utilisation</span> */}
                <h5
                  className="footer__content__link__title"
                  onClick={() => {
                    navigate("terms_of_use");
                    window.scrollTo(0, 0);
                  }}
                >
                  Conditions d'utilisation
                </h5>
                {/* <span>Politique de retour</span> */}
                <h5 className="footer__content__link__title">
                  Politique de retour
                </h5>
                {/* <span>Politique de livraison</span> */}
                <h5 className="footer__content__link__title">
                  Politique de livraison
                </h5>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className={classes.footer__content}>
            <div className={classes.footer__content__container}>
              <h4 className="footer-title">CONTACT</h4>
              <div className={classes.footer__links}>
                <div className={classes.footer__contact__container}>
                  <span className={classes.footer__links__icon}>
                    <MailOutlineIcon style={{ color: "#16AAE0" }} />
                  </span>
                  <h5 className="footer__content__text">
                    info@icebergtech.net
                  </h5>
                </div>
                <div className={classes.footer__contact__container}>
                  <span className={classes.footer__links__icon}>
                    <PhoneIphoneIcon style={{ color: "#16AAE0" }} />
                  </span>
                  <h5 className="footer__content__text">+212 6 66 66 66 66</h5>
                </div>
                <span>
                  <span className={classes.footer__links__icon}>
                    <i className="fas fa-envelope"></i>
                  </span>
                </span>
                <div className={classes.footer__contact__container}>
                  <span className={classes.footer__links__icon}>
                    <HomeOutlinedIcon style={{ color: "#16AAE0" }} />
                  </span>
                  <h5 className="footer__content__text">
                    12 rue de la paix, casablanca 20000
                  </h5>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className={classes.footer__bottom__container}>
          <Divider className={classes.footer__divider} />
          <h5 className="footer__bottom__text">
            © 2022 <a href="https://icebergtech.net">Iceberg Tech</a> Store.
            Tous droits réservés.
          </h5>
        </div>
      </Box>
    </div>
  );
}
