import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Button from "@mui/material/Button";
import { useStateContext } from "../../context/stateContext";
import { CardModel } from "../../types/CardModels/CardModel";
import toast from "react-hot-toast";
import useStyles from "./styles";

interface CartCardProps {
  card: CardModel;
}

type userInfoType = {
  nom: string;
  givenName: string;
  familyName: string;
  token: string;
};

export const CartCard = ({ card }: CartCardProps) => {
  const { qtty, setQtty, totalQuantity, setTotalQuantity } = useStateContext();
  const classes = useStyles();

  const onDelete = (id: number) => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "DELETE",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
    };

    fetch(
      process.env.REACT_APP_API_URL + `/api/panier/${id}`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("data is ", data);
        toast.success("Card deleted successfully");
        setTotalQuantity(totalQuantity - 1);
      })
      .catch((error) => {
        toast.success("Card deleted successfully");
        setTotalQuantity(totalQuantity - 1);
        console.log("error is ", error);
      });
  };

  const onIncDecQuantity = (id: string, qty: number) => {
    if (qty < 0 && card.quantite === 1) {
      toast.error("You can't have less than 1 quantity");
      return;
    }
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
      body: JSON.stringify({
        quantite: card.quantite + qty,
        produitcouleurattribut: card.produitcouleurattribut.id,
      }),
    };

    fetch(
      process.env.REACT_APP_API_URL + `/api/panier/${card.id}/`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message && data.message.includes("Quantité restante")) {
          toast.error(data.message);
        } else {
          if (qty > 0) {
            toast.success("Card added 1 quantity successfully");
          } else if (qty < 0) {
            toast.success("Card removed 1 quantity successfully");
          }
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setQtty(qtty + 1);
        }
      })
      .catch((error) => {
        toast.error("Card added failed");
      });
  };

  return (
    <div className={classes.card}>
      <img
        className={classes.card__image}
        src={card.produitcouleur.photo_produit}
        alt={`${card.produitcouleurattribut.id}`}
      ></img>
      <Stack direction="column" gap={1.2} className={classes.card__info}>
        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          style={{
            maxWidth: "220px",
          }}
        >
          {card.produitcouleur.nom_produit}
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
          className={classes.cardAttributes}
        >
          {card.produitcouleurattribut.attribut!.nom}
        </Typography>
        <Stack
          direction="row"
          spacing={qtty > 9 ? 1 : 2}
          className={classes.cardQuantity}
        >
          <Typography variant="h6" style={{ fontWeight: "100" }}>
            {card.quantite}
          </Typography>
        </Stack>
      </Stack>
      <div className={classes.card__price__delete}>
        <Typography gutterBottom variant="h6" component="h2">
          {card.produitcouleur.prix} MAD
        </Typography>
      </div>
    </div>
  );
};
