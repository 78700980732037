import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",

    rowGap: "22px",
    columnGap: "22px",
  },
  addToCart: {
    fontSize: ".8rem",
    textAlign: "center",
    alignSelf: "center",
    marginRight: "0.5rem",
  },

  addToCartAndFavoris: {
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  reviewsText: {
    fontSize: "0.85rem",
  },
  ratingGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "1.2rem",
  },
  media: {
    height: "260px",
    width: "260px",
  },
  title: {
    color: "black",
    fontSize: ".67rem",
    fontWeight: "bold",
    lineHeight: "1.5rem",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  text__title: {
    marginTop: "1.2rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
    },
  },
  text__description: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
      paddingTop: "0.4rem",
    },
  },

  collections__products__card: {
    width: 256,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "0.4rem",
      paddingRight: "0.4rem",
    },
  },
  descriptionSection: {
    paddingBottom: "1.5rem",
  },
}));
