import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Card, CardMedia, Typography, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  ProductModel,
  ProductsListModel,
} from "../../../types/productsList/productsLIst";
import useStyles from "./styles";
import { PopularCollectionsCard3 } from "./PopularCollectionsCard3";
import Skeleton from "@mui/material/Skeleton";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,

  height: "100%",
  textAlign: "center",
  color: theme.palette.text.secondary,
  overflow: "hidden",
  cursor: "pointer",

  img: {
    width: "100%",
    height: "100%",
    // full height of the item
    objectFit: "cover",
    objectPosition: "center",
  },
  //hover effect
  "&:hover": {
    img: {
      transform: "scale(1.1)",
      transition: "all 0.5s ease-in-out",
    },
  },
}));

export default function PopularCollections() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const isLargeScreen = useMediaQuery("(min-width:1470px)");
  const classes = useStyles();

  const [productsList, setProductsList] = useState<
    ProductModel[] | "error" | "loading"
  >("loading");

  const [productsList2, setProductsList2] = useState<
    ProductModel[] | "error" | "loading"
  >("loading");

  // const [data2] = useAxios({
  //   axiosInstance: axios,
  //   method: "GET",
  //   endpoint: "/api/nouvelle-collection/actif/",
  // });
  // let NewCastMenuData = data2 as unknown as NewMenuModel;

  useEffect(() => {
    const requestOptions = {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(
      process.env.REACT_APP_API_URL + `/api/produit?categorie=39`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setProductsList(data.results as ProductModel[]);
      })
      .catch((error) => {
        setProductsList("error");
        console.log("error is ", error);
      });

    fetch(
      process.env.REACT_APP_API_URL + `/api/produit?categorie=63`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setProductsList2(data.results as ProductModel[]);
      })
      .catch((error) => {
        setProductsList2("error");
        console.log("error is ", error);
      });
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <h4 className="text-title text-center ">POPULAR COLLECTIONS</h4>
      <Grid
        container
        justifyContent="space-between"
        style={{
          maxWidth: "1470px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          height={isMobile ? "65vw" : isLargeScreen ? "367px" : "25vw"}
          sx={{ paddingRight: "3px", paddingBottom: "3px" }}
        >
          {productsList === "loading" ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : productsList === "error" ? (
            <div></div>
          ) : (
            <PopularCollectionsCard3
              productsList={productsList}
              index={1}
              size={3}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ paddingRight: "3px", paddingBottom: "3px", paddingLeft: "3px" }}
        >
          {productsList === "loading" ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : productsList === "error" ? (
            <div></div>
          ) : (
            <PopularCollectionsCard3
              productsList={productsList}
              index={1}
              size={3}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ paddingLeft: "3px", paddingBottom: "3px" }}
        >
          {/* <PopularCollectionsCard3
            productsList={productsList}
            index={2}
            size={3}
          /> */}

          {productsList2 === "loading" ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : productsList2 === "error" ? (
            <div></div>
          ) : (
            <PopularCollectionsCard3
              productsList={productsList}
              index={2}
              size={3}
            />
          )}
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={3}
          height={isMobile ? "100vw" : "25vw"}
          sx={{ paddingRight: "3px", paddingTop: "3px" }}
        >
          <PopularCollectionsCard3 productsList={productsList2} index={0} />
        </Grid> */}
        <Grid
          item
          xs={12}
          md={6}
          height={isMobile ? "65vw" : isLargeScreen ? "367px" : "25vw"}
          sx={{ paddingLeft: "3px", paddingTop: "3px", paddingRight: "3px" }}
        >
          {/* <PopularCollectionsCard3
            productsList={productsList2}
            index={1}
            size={6}
          /> */}

          {productsList2 === "loading" ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : productsList2 === "error" ? (
            <div></div>
          ) : (
            <PopularCollectionsCard3
              productsList={productsList2}
              index={1}
              size={6}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          height={isMobile ? "65vw" : isLargeScreen ? "367px" : "25vw"}
          sx={{
            paddingLeft: "3px",
            paddingTop: "3px",
          }}
        >
          {/* <PopularCollectionsCard3
            productsList={productsList2}
            index={2}
            size={6}
          /> */}
          {productsList2 === "loading" ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : productsList2 === "error" ? (
            <div></div>
          ) : (
            <PopularCollectionsCard3
              productsList={productsList2}
              index={2}
              size={6}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
