import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import useStyles from "./styles";

export const LoadingCard = () => {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.collections__products__card} elevation={1}>
        <Skeleton
          variant="rectangular"
          className={classes.media}
          height={300}
        />{" "}
        <Box sx={{ pt: 0.5 }}>
          <Skeleton width="60%" style={{ marginLeft: "10px" }} />
          <Skeleton width="100px" style={{ marginLeft: "10px" }} />
          <Skeleton width="180px" style={{ marginLeft: "10px" }} />
          <div />
          <Skeleton width="100px" style={{ marginLeft: "10px" }} />
        </Box>
      </Card>
    </div>
  );
};
