import React from "react";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import CollectionsProductCard from "../Collections/CollectionsProductsList/CollectionsProductCard";
import { userInfoType } from "../../types/user/user";
import { FavorisModel } from "../../types/FavorisModels/FavorisModel";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { LodingCardsList } from "../Collections/CollectionsProductsList/LodingCardsList";

export const FavorisContainer = () => {
  const classes = useStyles();

  const {
    data,
    isLoading,
    isError,
    isFetching,
    isSuccess,
  }: UseQueryResult<FavorisModel, Error> = useQuery<FavorisModel, Error>(
    "userFavoris",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/userfavoris/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  if (isLoading || isFetching) {
    return <LodingCardsList />;
  } else if (isError) {
    return <div className="error_container">Quelque chose s'est mal passé</div>;
  } else {
    return (
      <>
        {isSuccess && (
          <div className={classes.favorite__containers}>
            {data?.results?.length > 0 ? (
              data.results.map((product, index) => (
                <CollectionsProductCard key={index} {...product.produit} />
              ))
            ) : (
              <Typography
                variant="h5"
                align="center"
                className={classes.noFavorisText}
              >
                Aucun produit dans votre liste de favoris
              </Typography>
            )}
          </div>
        )}
      </>
    );
  }
};
