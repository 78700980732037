import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 20,
    label: "200",
  },
  {
    value: 37,
    label: "370",
  },
  {
    value: 100,
    label: "1000 MAD",
  },
];

const RangeSliderCollections = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  // const [expanded, setExpanded] = React.useState<string | false>("panel1");

  

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const [value, setValue] = React.useState<number[]>([0, 100]);

  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <h3 className="text-body mt-2">
          Selectionner le prix <span className="text-muted">(0-1000 MAD)</span>
        </h3>
      </AccordionSummary>
      <Box
        sx={{
          width: 270,
          marginTop: "1rem",
          marginBottom: "2rem",
          marginLeft: "1rem",
        }}
      >
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={value}
          onChange={handleChangeSlider}
          getAriaValueText={valuetext}
          marks={marks}
          sx={{
            color: "gray",
          }}
        />
      </Box>
    </Accordion>
  );
};

function valuetext(value: number) {
  return `${value * 10} dirhams`;
}

export default RangeSliderCollections;
