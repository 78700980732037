import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { UserModel } from "../../types/UserModels/UserModel";
import { userInfoType } from "../../types/user/user";
import Autocomplete from "@mui/material/Autocomplete";
import { VillesModel } from "../../types/user/villesModel";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { GetLocation } from "./GetLocation";
import { useParams } from "react-router-dom";

export const ProfilePage = () => {
  const params = useParams();
  const nav_to_back_or_basket = params.name;
  const [userInfo, setUserInfo] = React.useState<UserModel | null>(null);
  const [villes, setVilles] = React.useState<VillesModel | null>(null);
  const [userId, setUserId] = React.useState<UserModel | null>(null);

  const classes = useStyles();
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");
  const navigate = useNavigate();

  useEffect(() => {
    const userinfox = localStorage.getItem("profile");
    if (userinfox) {
      setUserId(JSON.parse(userinfox));
    }
    console.log("Our user id is <---> ", data?.id);
  }, [userId === null]);

  const {
    data: villeData,
    isLoading: villeIsLoading,
    isError: villeIsError,
    isSuccess: villeIsSuccess,
    refetch: villeRefetch,
  }: UseQueryResult<VillesModel, Error> = useQuery<VillesModel, Error>(
    "ville",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/ville/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 2, // 2 minutes
      onSuccess: (data) => {
        console.log("data is ", data);
        setVilles(data);

        // setUserInfo(data);
      },
    }
  );

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    refetch,
  }: UseQueryResult<UserModel, Error> = useQuery<UserModel, Error>(
    "profile",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/me/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: 1000 * 60 * 2, // 2 minutes
      onSuccess: (data) => {
        console.log("data is ", data);

        setUserInfo(data);
      },
    }
  );

  const handleSubmit = () => {
    const userinfo = localStorage.getItem("profile");

    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    // const userinfox = localStorage.getItem("profile");
    // if (userinfox) {
    //   setUserId(JSON.parse(userinfox));
    // }

    // console.log("our user id is ", userId);
    const current_city = villeData?.results.filter((ville) => {
      return ville.nom === userInfo?.address.nom_ville;
    });
    console.log("villes list is ", villeData);
    let city_id = current_city?.[0].id;
    console.log("current_city id is ... " + city_id);

    // from here
    const requestOptions1 = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
      body: JSON.stringify({
        adresse: userInfo?.address?.adresse,
        complement_adresse: userInfo?.address?.complement_adresse,
        telephone: userInfo?.address?.telephone,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        // ville: userInfo?.address.id,
        ville: city_id,
        user: data?.id,
        // user: JSON.parse(localStorage.getItem("profile")!).id,
      }),
    };

    // userAddressRefetch();
    // to here

    const requestOptions = {
      method: "PATCH",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

        authorization: `Bearer ${convertedUserInfo.token}`,
      },
      body: JSON.stringify({
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/api/user/me/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        toast.success("Modification effectuée avec succès");
        // nav_to_back_or_basket
        // console.log("api user is ", data);
      })
      .catch((error) => {
        toast.error("Une erreur est survenue");
        console.log("error is ", error);
        console.log("Patch There was an error!", error);
      });
    fetch(process.env.REACT_APP_API_URL + "/api/user-address/", requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        console.log("data is register with address", data);
        toast.success("Profile updated successfully");
        console.log("nav_to_back_or_basket is " + nav_to_back_or_basket);
        if (nav_to_back_or_basket === "add_product") {
          navigate("/basket");
        } else {
          navigate(-1);
        }
        // console.log("api user-address is ", data);
        // toast.success("Votre adresse a été modifiée avec succès");
      })
      .catch((error) => {
        // console.log("Error: ", error);
        toast.error("Erreur lors de la modification de l'adresse");
        console.log("Post There was an error!", error);
      });
    // navigate(-1);
  };

  return (
    <div className={classes.container}>
      <Card className={classes.root} elevation={isMobileOrTablet ? 0 : 1}>
        <Grid container spacing={3}>
          <Grid item xs={isMobileOrTablet ? 12 : 6}>
            <TextField
              error={userInfo?.first_name === ""}
              helperText={
                userInfo?.first_name === "" ? "Champ obligatoire" : ""
              }
              id="outlined-basic"
              value={userInfo?.first_name ?? ""}
              onChange={(e) => {
                if (userInfo) {
                  setUserInfo({ ...userInfo, first_name: e.target.value });
                }
              }}
              variant="outlined"
              label={"Prénom"}
              focused={userInfo?.first_name ? true : false}
              InputLabelProps={{
                style: {
                  color: "grey",
                },
              }}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={isMobileOrTablet ? 12 : 6}>
            <TextField
              id="outlined-basic"
              value={userInfo?.last_name ?? ""}
              error={userInfo?.last_name === ""}
              helperText={userInfo?.last_name === "" ? "Champ obligatoire" : ""}
              onChange={(e) => {
                if (userInfo) {
                  setUserInfo({ ...userInfo, last_name: e.target.value });
                }
              }}
              label={"Nom"}
              variant="outlined"
              focused={userInfo?.last_name ? true : false}
              InputLabelProps={{
                style: {
                  color: "grey",
                },
              }}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              value={userInfo?.address?.adresse ?? ""}
              onChange={(e) => {
                if (userInfo) {
                  setUserInfo({
                    ...userInfo,
                    address: { ...userInfo.address, adresse: e.target.value },
                  });
                }
              }}
              error={userInfo?.address?.adresse === ""}
              helperText={
                userInfo?.address?.adresse === "" ? "Champ obligatoire" : ""
              }
              label={"Adresse"}
              variant="outlined"
              focused={userInfo?.address?.adresse ? true : false}
              InputLabelProps={{
                style: {
                  color: "grey",
                },
              }}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              value={userInfo?.address?.complement_adresse ?? ""}
              onChange={(e) => {
                if (userInfo) {
                  setUserInfo({
                    ...userInfo,
                    address: {
                      ...userInfo.address,
                      complement_adresse: e.target.value,
                    },
                  });
                }
              }}
              label={"Complément d'adresse"}
              variant="outlined"
              focused={userInfo?.address?.complement_adresse ? true : false}
              InputLabelProps={{
                style: {
                  color: "grey",
                },
              }}
              className={classes.input}
            />
          </Grid>

          <Grid item xs={isMobileOrTablet ? 12 : 6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "75px",
              }}
            >
              <Autocomplete
                value={userInfo?.address?.nom_ville ?? ""}
                clearIcon={null}
                inputValue={userInfo?.address?.nom_ville ?? ""}
                defaultValue={
                  userInfo?.address?.nom_ville ?? "selectionner une ville"
                }
                style={{
                  width: "100%",
                  height: "70px",
                }}
                options={villes?.results?.map((ville) => ville.nom) ?? []}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const ville = villes?.results?.find(
                      (ville) => ville.nom === newValue
                    );
                    if (ville) {
                      setUserInfo({
                        ...userInfo!,
                        address: {
                          ...userInfo!.address,
                          nom_ville: ville.nom,
                          id: ville.id,
                        },
                      });
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    style={{
                      width: "100%",
                      height: "60px",
                      position: "relative",
                    }}
                    {...params}
                    focused={userInfo?.address?.nom_ville ? true : false}
                    label={"ville"}
                    error={userInfo?.address?.nom_ville === ""}
                    helperText={
                      userInfo?.address?.nom_ville === ""
                        ? "Champ obligatoire"
                        : ""
                    }
                    InputLabelProps={{
                      style: {
                        color: "grey",
                      },
                    }}
                    onChange={(e) => {
                      if (userInfo) {
                        setUserInfo({
                          ...userInfo,
                          address: {
                            ...userInfo?.address,
                            nom_ville: e.target.value,
                          },
                        });
                      }
                    }}
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={isMobileOrTablet ? 12 : 6}>
            <TextField
              id="outlined-basic"
              value={userInfo?.address?.telephone ?? ""}
              onChange={(e) => {
                if (userInfo) {
                  setUserInfo({
                    ...userInfo,
                    address: {
                      ...userInfo?.address,
                      telephone: e.target.value,
                    },
                  });
                }
              }}
              label={"téléphone"}
              type="phone"
              variant="outlined"
              focused={userInfo?.address?.telephone ? true : false}
              error={userInfo?.address?.telephone === ""}
              helperText={
                userInfo?.address?.telephone === "" ? "Champ obligatoire" : ""
              }
              InputLabelProps={{
                style: {
                  color: "grey",
                },
              }}
              className={classes.input}
            />
          </Grid>

          <Grid item xs={12}>
            <GetLocation />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={classes.button}
              disabled={
                userInfo?.first_name === "" ||
                userInfo?.last_name === "" ||
                userInfo?.address?.nom_ville === "" ||
                userInfo?.address?.telephone === "" ||
                userInfo?.address?.adresse === ""
              }
            >
              <Typography variant="h5" component="h2">
                Enregistrer
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
