import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  noFavorisText: {
    marginTop: "30vh",
    paddingTop: "30vh",
    textAlign: "center",
    width: "100%",
  },
  favorite__containers: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    rowGap: "12px",
    columnGap: "12px",
    alignItems: "center",
    justifyContent: "start",
    maxWidth: "1420px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

  Favoris__container: {
    maxWidth: "1470px",
    margin: "auto",
    padding: "1rem 0",
  },
}));
