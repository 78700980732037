import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Button, CardMedia, Grid, Typography } from "@material-ui/core";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {
  CouleurModel,
  ProductModel,
} from "../../../types/productsList/productsLIst";
import { Link } from "react-router-dom";
import useStyle from "./styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,

  height: "100%",
  textAlign: "center",
  color: theme.palette.text.secondary,
  overflow: "hidden",
  cursor: "pointer",

  img: {
    width: "100%",
    height: "100%",
    // full height of the item
    objectFit: "cover",
    objectPosition: "center",
  },
  //hover effect
  "&:hover": {
    img: {
      transform: "scale(1.1)",
      transition: "all 0.5s ease-in-out",
    },
  },
}));

const CollectionsProductCard = ({
  id,
  nom,
  prix,
  photo_principal,
  favoris,
  couleurs,
  is_new,
  promotion,
}: ProductModel) => {
  const navigate = useNavigate();
  const classes = useStyle();
  return (
    <div>
      <Card className={classes.collections__products__card} elevation={1}>
        <Item>
          <Link className={classes.link} to={`/product_details/${id}`}>
            <div style={{ position: "relative" }}>
              {is_new && (
                <Typography
                  variant="caption"
                  style={{
                    color: "#fff",
                    position: "absolute",
                    top: 4,
                    right: 4,
                    zIndex: 999,
                    backgroundColor: "#f44336",
                    paddingLeft: 4,
                    paddingRight: 4,
                    borderRadius: 4,
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  Nouveau
                </Typography>
              )}
              {promotion && (
                <Typography
                  variant="caption"
                  style={{
                    color: "#fff",
                    position: "absolute",
                    top: is_new ? 24 : 4,
                    right: 4,
                    zIndex: 999,
                    backgroundColor: "#f44336",
                    paddingLeft: 4,
                    paddingRight: 4,
                    borderRadius: 4,
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  Soldé
                </Typography>
              )}
            </div>

            <CardMedia
              onClick={() => {
                // alert("clicked on the image");
                document.body.scrollTop = 0;
              }}
              className={classes.media}
              component="img"
              image={photo_principal}
              key={photo_principal}
            />
          </Link>
        </Item>

        {/* <Typography variant="h5" className={classes.title}> */}
        <h5 className="text-subtitle2 m-2">
          {nom.length > 20 ? nom.split(" ").slice(0, 2).join(" ") + "..." : nom}
        </h5>
        {/* </Typography> */}

        {/* <Grid container className={classes.ratingGrid}>
          <Grid item xs={8}>
            <Rating
              name="size-small"
              size="small"
              value={5}
              sx={{ marginLeft: "0.4rem", marginRight: "0.4rem" }}
              onChange={(event, newValue) => {
                // setValue(newValue);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.reviewsText}>
              <h6 className="text-caption2 m-0">5 Reviews</h6>
            </div>
          </Grid>
        </Grid> */}

        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", mr: 2, ml: 1.1 }}
        >
          <Typography variant="subtitle1" style={{ fontSize: ".72rem" }}>
            {promotion ? (
              <div>
                <span
                  style={{ textDecoration: "line-through", color: "#9e9e9e" }}
                >
                  {prix}
                </span>
                <span style={{ color: "#f44336", paddingLeft: 4 }}>
                  {promotion.prix} MAD
                </span>
              </div>
            ) : (
              <span>{prix} MAD</span>
            )}
          </Typography>
          <AvatarGroup sx={{ alignItems: "center", marginBottom: "0.2rem" }}>
            {couleurs.map((color: CouleurModel) => (
              <Avatar
                key={color.id}
                style={{
                  position: "relative",
                  cursor: "pointer",
                  backgroundColor:
                    color?.code.indexOf("#") === -1
                      ? "#" + color?.code
                      : color?.code,
                  borderRadius: "50%",
                  width: "19px",
                  height: "19px",
                }}
              >
                <div />
              </Avatar>
            ))}
          </AvatarGroup>
        </Stack>
        <Stack direction="row" className={classes.addToCartAndFavoris}>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/product_details/${id}`);
            }}
          >
            <div className={classes.addToCart}>
              <h6 className="text-body-bold m-0">Ajouter au panier</h6>
            </div>
            <AddShoppingCartIcon sx={{ fontSize: ".9rem" }} />
          </Button>

          {favoris ? (
            <Button variant="text">
              <FavoriteIcon sx={{ fontSize: ".9rem" }} />
            </Button>
          ) : (
            <Button variant="text">
              <FavoriteBorderIcon sx={{ fontSize: ".9rem" }} />
            </Button>
          )}
        </Stack>
      </Card>
    </div>
  );
};

export default CollectionsProductCard;
