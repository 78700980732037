import {
  AppBar,
  Badge,
  Slide,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
} from "@material-ui/core";
import React, { useEffect } from "react";
import toast from "react-hot-toast";

import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/pngs/logo.png";
import useStyles from "./styles";
import { ShoppingCart, FavoriteBorderOutlined } from "@material-ui/icons";
import SecondNavBar from "./SecondNavBar";
import { Link } from "react-router-dom";
import { useStateContext } from "../../context/stateContext";
import MenuOptions from "./MenuOptions";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SearchModel } from "../../types/SearchModels/SearchModel";

type Props = {
  setOpenSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setSearchData: React.Dispatch<React.SetStateAction<SearchModel | null>>;
  searchData: SearchModel | null;
};

export const SearchModal = ({
  setOpenSearchModal,
  setSearchQuery,
  setSearchData,
  searchData,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div
      className={classes.menu__search__modal__container}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        position: "absolute",
        left: "48.7%",
        transform: "translateX(-50%)",
        backgroundColor: "white",
        borderRadius: "5px",
        padding: "5px 10px",
        zIndex: 100,
        top: "60px",
        maxHeight: "70vh",
        overflowY: "scroll",
        border: "0.5px solid #e0e0e0",
        paddingTop: "10px",
      }}
    >
      <CloseRoundedIcon
        onClick={() => {
          setOpenSearchModal(false);
          setSearchQuery("");
          setSearchData(null);
        }}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          cursor: "pointer",
        }}
      />
      {searchData?.results?.map((item) => (
        <Box
          key={item.id}
          sx={{ display: "flex", mb: 2, cursor: "pointer" }}
          onClick={() => {
            setOpenSearchModal(false);
            setSearchQuery("");
            setSearchData(null);
            navigate(`/product_details/${item.id}`);
          }}
        >
          <img
            src={item.photo_principal}
            alt="product"
            style={{ width: "100px", height: "100px" }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h6">{item.nom}</Typography>
            <Typography variant="h6">{item.prix} MAD</Typography>
          </Box>
        </Box>
      ))}
    </div>
  );
};
