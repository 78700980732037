import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GoogleLogin } from "react-google-login";
import { useGoogleLogin } from "react-google-login";
import { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import { useDispatch } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-hot-toast";
import FacebookLogin from "react-facebook-login";
import Modal from "@mui/material/Modal";
import { userInfoType } from "../../types/user/user";
import useStyles from "./styles";

const clientId = "RLH04klrRXYgK2jBqmaWk3ekPrbAU69gkMs8V662";
const clientSecret =
  "zVJsS9QSpoB37Fws1Eyp1kYxyZgQeBfZnuAHZnnFReWlupk0ZxOKKNeEY5JIUf5nKZgMTewj3EZ4pZnAwtS3Tg8y7SuFv6brTHIkxOIrsODxKFqUi1WZv6zEOMXWyYHv";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://localhost:3000/login">
        APP TO GET
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

type loginPageProps = {
  navigateTo: string;
};

export default function LoginPage(props: loginPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [sendRequest, setSendRequest] = useState(false);
  const [token, setToken] = useState(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [condition, setCondition] = useState(false);
  const [userTamporaryInfo, setUserTamporaryInfo] =
    useState<userInfoType | null>(null);
  const [isLoged, setIsLoged] = useState<boolean | null>(null);

  const classes = useStyles();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const onGoogleSignInSuccess = async (response: any) => {
    const result = response?.profileObj;
    const google_sign_in_token = response?.accessToken;
    setToken(google_sign_in_token);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ grant_type: "convert_token" }),
    };

    await fetch(
      `${process.env.REACT_APP_API_URL}/auth/convert-token?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&backend=google-oauth2&token=${google_sign_in_token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // check if user is logged in
        // console.log("data", data, "token", google_sign_in_token);
        if (data.access_token) {
          // console.log("data.access_token", data.access_token);
          try {
            setUserTamporaryInfo({
              nom: result?.name,
              givenName: result?.givenName,
              familyName: result?.familyName,
              token: "",
              email: result?.email,
              picture: result?.imageUrl,
            });
            setAccessToken(data.access_token);
            setOpenLoading(true);
            checkUserConditionCall(
              data.access_token,
              result?.imageUrl,
              result?.givenName,
              result?.familyName,
              result?.email
            );
          } catch (error) {
            console.log(error);
          }
          // console.log("user", JSON.stringify(data));
          setLoginData(true);
        } else {
          localStorage.setItem("user", JSON.stringify(null));
          setLoginData(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onGoogleSignInFailure = (error: any) => {
    toast.error("Google sign in was unsuccessful. Try again later");
    console.log("google sign in error", error);
  };

  //! facebook login

  const responseFacebook = async (response: any) => {
    // console.log("response", response);
    // const result = response?.profileObj;
    const facebook_sign_in_token = response?.accessToken;
    setToken(facebook_sign_in_token);

    console.log("facebook_sign_in_token", facebook_sign_in_token);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ grant_type: "convert_token" }),
    };

    await fetch(
      `${process.env.REACT_APP_API_URL}/auth/convert-token?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&backend=facebook&token=${facebook_sign_in_token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.access_token) {
          try {
            setUserTamporaryInfo({
              nom: response?.name,
              givenName: response?.name?.split(" ")[0],
              familyName: response?.name?.split(" ")[1],
              token: "",
              email: response?.email,
              picture: response?.picture.data.url,
            });
            setAccessToken(data.access_token);
            setOpenLoading(true);
            checkUserConditionCall(
              data.access_token,
              response?.picture.data.url,
              response?.name?.split(" ")[0],
              response?.name?.split(" ")[1],
              response?.email
            );
          } catch (error) {
            console.log(error);
          }
          console.log("user", JSON.stringify(data));
          setLoginData(true);
        } else {
          localStorage.setItem("user", JSON.stringify(null));
          setLoginData(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const componentClicked = () => {
    console.log("facebook login clicked");
  };

  //! check conditions
  const checkUserConditionCall = async (
    token: string,
    picture: string,
    givenName: string,
    familyName: string,
    email: string
  ) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(process.env.REACT_APP_API_URL + "/api/user/me/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          try {
            if (data.condition_general) {
              console.log("data to dispatch", givenName, familyName, email);

              dispatch({
                type: "AUTH",

                data: {
                  imageUrl: picture,
                  givenName: givenName,
                  familyName: familyName,
                  email: email,
                  token: token,
                },
              });
              setOpenLoading(false);
              navigate("/");
            } else {
              setOpenModal(true);
            }
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //! post to backend

  const [loginData, setLoginData] = useState(false);

  useEffect(() => {
    localStorage.getItem("profile") != null
      ? setIsLoged(true)
      : setIsLoged(false);
    const initClient = () => {
      gapi.client.init({
        // clientId:
        //   "514295346308-jumpn2o3bnjc98qpthk1habl2ekotek1.apps.googleusercontent.com",
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
        scope: "email",
        plugin_name: "App Name that you used in google developer console API",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const setConditionApi = (token: string) => {
    const requestOptions = {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    fetch(
      process.env.REACT_APP_API_URL + `/api/user/me/set_condition_general/`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        toast.success("login réussi");
        dispatch({
          type: "AUTH",

          data: {
            imageUrl: userTamporaryInfo?.picture,
            givenName: userTamporaryInfo?.givenName,
            familyName: userTamporaryInfo?.familyName,
            email: userTamporaryInfo?.email,
            token: accessToken,
          },
        });

        navigate("/");
      })
      .catch((error) => {
        toast.error("une erreur est survenue");
      })
      .then(() => {
        window.location.reload();
      });
  };

  if (isLoged) {
    return <Navigate replace to="/" />;
  } else if (isLoged === false) {
    return (
      <div className={classes.container}>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <div className="g-signin2" data-onsuccess="onSignIn"></div>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Modal
                keepMounted
                open={openModal}
                // onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: "red",
                  background: "rgba(0,0,0,0.5)",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                    height: "80%",
                    display: "flex",
                    selfAlign: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  <Typography
                    id="keep-mounted-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Conditions générales d'utilisation
                  </Typography>
                  <Typography
                    id="keep-mounted-modal-description"
                    sx={{ mt: 6 }}
                  >
                    S'il vous plait, acceptez nos conditions d'utilisation.
                  </Typography>

                  <Box className={classes.checkboxAcceptConditionsContainer}>
                    <Checkbox
                      checked={condition}
                      onChange={() => {
                        setCondition(!condition);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      J'accepte les conditions d'utilisation
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    disabled={!condition}
                    sx={{ mt: 2, backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      setConditionApi(accessToken ?? "");
                    }}
                  >
                    Valider
                  </Button>
                </Box>
              </Modal>
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box mt={1}>
              <Typography variant="body2" color="text.secondary" align="center">
                OR
              </Typography>
            </Box>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoading}
              // onClick={}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Box className={classes.facebookAndGoogleContainer}>
              <div id="signInButton">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
                  // clientId="514295346308-jumpn2o3bnjc98qpthk1habl2ekotek1.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  className={classes.googleLogin}
                  render={(renderProps) => (
                    <Button
                      variant="contained"
                      className={classes.mGoogleButton}
                      onClick={renderProps.onClick}
                      // disabled={renderProps.disabled}
                    >
                      {/* <img src="https://img.icons8.com/color/48/000000/google-logo.png" /> */}
                      {renderProps.disabled ? (
                        <CircularProgress
                          color="inherit"
                          size={30}
                          sx={{ color: "white" }}
                        />
                      ) : (
                        <Typography className={classes.googleLoginText}>
                          Login with Google
                        </Typography>
                      )}
                    </Button>
                  )}
                  onSuccess={(response: any) => {
                    toast.success("vous êtes connecté");
                    onGoogleSignInSuccess(response);
                    setSendRequest(true);
                  }}
                  onFailure={onGoogleSignInFailure}
                  cookiePolicy={"single_host_origin"}
                  // isSignedIn={true}
                />

                <div className={classes.facebookLogin}>
                  <FacebookLogin
                    // appId="1222213921954390"

                    appId={process.env.REACT_APP_FACEBOOK_ID ?? ""}
                    autoLoad={false}
                    fields="first_name, last_name ,email, picture"
                    onClick={componentClicked}
                    cssClass="my-facebook-button-class"
                    callback={responseFacebook}
                    disableMobileRedirect={true}
                    redirectUri={window.location.origin}

                    // icon="fa-facebook"
                  />
                </div>
              </div>
            </Box>

            <Copyright className={classes.copyright} />
          </Container>
        </ThemeProvider>
      </div>
    );
  } else {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress disableShrink className={classes.progressCircle} />
      </div>
    );
  }
}
