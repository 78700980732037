import React from "react";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";

export const LoadingProductCard = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <Card className={classes.commandeContainerCard__loading}>
        <Box className={classes.commandeContainerBox}>
          <Skeleton variant="rectangular" height={15} width={120} />
          <Skeleton variant="rectangular" width="30%" height={20} />
        </Box>
        <Divider variant="middle" />
        <CardContent className={classes.card__content}>
          <div className={classes.loading__card__content__stack}>
            <Skeleton variant="rectangular" height={270} width={180} />
            <Box className={classes.loading__card__content__box}>
              <Skeleton variant="rectangular" width="90%" height={20} />
              <Skeleton
                variant="rectangular"
                width="50%"
                height={15}
                style={{ margin: "10px 0" }}
              />
              <Skeleton variant="rectangular" width="70%" height={15} />
            </Box>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
