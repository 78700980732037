import React from "react";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";

interface Props {
  isMobileOrTablet: boolean;
}

export const LoadingProductDetailsPage = ({ isMobileOrTablet }: Props) => {
  return (
    <Box style={{ maxWidth: "1470px", margin: "0 auto" }}>
      <Box sx={{ height: "20vh" }} />
      <Grid container spacing={0}>
        <Grid item xs={isMobileOrTablet ? 12 : 5}>
          <Skeleton variant="rectangular" width={"100%"} height={600} />
        </Grid>
        <Grid item xs={isMobileOrTablet ? 12 : 7}>
          <div>
            <Skeleton
              variant="rounded"
              width={510}
              height={25}
              style={{ margin: "0 0 30px 10px" }}
            />
            <Skeleton
              variant="rounded"
              width={310}
              height={14}
              style={{ margin: "0 0 10px 10px" }}
            />
            <Skeleton
              variant="rounded"
              width={810}
              height={14}
              style={{ margin: "0 0 10px 10px" }}
            />
            <Skeleton
              variant="rounded"
              width={810}
              height={14}
              style={{ margin: "0 0 10px 10px" }}
            />
            <Skeleton
              variant="rounded"
              width={810}
              height={14}
              style={{ margin: "0 0 10px 10px" }}
            />
            <Skeleton
              variant="rectangular"
              width={810}
              height={14}
              style={{ margin: "0 0 10px 10px" }}
            />

            <Skeleton
              variant="rounded"
              width={200}
              height={50}
              style={{ margin: "40px 0 30px 10px" }}
            />

            <Skeleton
              variant="rectangular"
              width={610}
              height={14}
              style={{ margin: "0 0 10px 10px", backgroundColor: "#f794ab" }}
            />

            <Skeleton
              variant="rectangular"
              width={810}
              height={14}
              style={{ margin: "0 0 10px 10px" }}
            />

            <Skeleton
              variant="rectangular"
              width="100%"
              height={60}
              style={{ margin: "50px 0 5px 10px" }}
            />

            <Skeleton
              variant="rectangular"
              width="100%"
              height={60}
              style={{ margin: "0px 0 5px 10px" }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={60}
              style={{ margin: "0px 0 5px 10px" }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
