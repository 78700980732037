import { makeStyles, alpha } from "@material-ui/core";

export default makeStyles((theme) => ({
  appBar: {
    width: "100vw",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "1470px",
    margin: "0 auto",
    padding: "0",
  },
  secondaryToolbar: {
    display: "flex",
    flexWrap: "wrap",
  },
  search: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: "50%",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  grow: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 1,
    alignItems: "center",
    display: "flex",
    textDecoration: "none",
    color: "black",
  },
  image: {
    width: "130px",
    height: "auto",
    marginRight: "10px",
  },
  hide__if__sm: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hide__if__md: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hide__if__xs: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hide__if__lg: {
    display: "block",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },

  login: {
    color: "#4d4d4d",
  },

  menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    maxWidth: "300px;",
  },

  avatar: {
    marginLeft: "20px",
    marginRight: "10px",
    cursor: "pointer",
  },

  menu__title: {
    marginBottom: "0.5rem",
    marginLeft: "4rem",
    marginRight: "4rem",
    color: "black",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "1rem",
      marginRight: "1rem",
      color: "red",
      width: "300px",
    },
  },

  menu__link: {
    // color: black;
    // font-size: 0.5rem;
    // margin-left: 2rem;
    // margin-right: 4rem;
    // text-decoration: none;
    textDecoration: "none",
    color: "black",
    marginLeft: "2rem",
    marginRight: "2rem",
  },

  // inherit from menu__link
  menu__link__active: {
    marginLeft: "2rem",
    marginRight: "2rem",
    textDecoration: "none",
    color: "green",
  },

  menu__image: {
    paddingLeft: "30px",
    height: 200,
    [theme.breakpoints.down("sm")]: {
      height: 200,
      paddingLeft: "0px",
      marginLeft: "0px",
      marginRight: "0px",
    },
  },

  menu__stack: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    justifyContent: "space-between",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "1rem",
    },
  },

  shopping__cart: {
    marginRight: "10px",
  },

  menu__search__container: {
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  sort__icon: {
    color: "black",
    size: "large",
  },
}));
