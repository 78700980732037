import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    minHeight: "85vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    marginLeft: "400px",
    marginRight: "400px",
    padding: "20px",
    maxWidth: "1470px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginRight: "0px",
      marginTop: "0px",
      elevation: "0",
      padding: "10px",
    },
  },
  button: {
    height: "55px",
    backgroundColor: "black",
    color: "white",
    margin: "1rem",
    marginTop: "2rem",
    width: "85%",
  },
  input: {
    width: "100%",
    height: "100%",
  },
}));
