import React, { useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import { useStateContext } from "../../context/stateContext";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";

import Stack from "@mui/material/Stack";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { CartCard } from "./cartCard";
import { useDispatch } from "react-redux";
import { CardModel } from "../../types/CardModels/CardModel";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { userInfoType } from "../../types/user/user";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { UserModel } from "../../types/UserModels/UserModel";
import { GetPromoCodeModel } from "../../types/PromoCode/getPromoCode";
import { CheckPromoCodeComponent } from "./checkPromoCode";
import { ReductionComponent } from "./reductionComponent";

export const Basket = () => {
  const [userInfo, setUserInfo] = React.useState<UserModel | null>(null);
  const {
    totalQuantity,
    showCart,
    setShowCart,
    setTotalQuantity,
    qtty,
    setQtty,
    promoCodeName,
    setPromoCodeName,
  } = useStateContext();
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [youHaveAPromoCode, setYouHaveAPromoCode] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState("");

  const { data, refetch }: UseQueryResult<CardModel[], Error> = useQuery<
    CardModel[],
    Error
  >(
    "cart",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/panier/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      onSuccess: (data) => {
        console.log("data is ", data);
        setTotalQuantity(data!.length);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [showCart, setTotalQuantity, totalQuantity, qtty]);

  const calculateTotal = () => {
    let total = 0;
    data?.forEach((item: CardModel) => {
      total += item.quantite * item.produitcouleur.prix;
    });
    return total;
  };

  let cartRef = useRef<HTMLDivElement>();

  // useEffect(() => {
  //   let handler = (event: any) => {
  //     if (!cartRef.current?.contains(event.target as Node)) {
  //       // document.body.style.overflow = "auto";
  //       setShowCart(false);
  //     }
  //     if (event.key === "Escape") {
  //       // document.body.style.overflow = "auto";
  //       setShowCart(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });

  const handleCommandeClick = () => {
    const fuserinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(fuserinfo!) as userInfoType;
    if (fuserinfo) {
      setUserInfo(JSON.parse(fuserinfo));
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
      body: JSON.stringify({
        codepromo: promoCodeName,
      }),
    };
    fetch(
      process.env.REACT_APP_API_URL + "/api/panier/valider/", // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.detail === "Adresse manquante") {
          toast.error("ajouter une adresse avant de valider la commande svp");
          //   navigate("profile_page");
        } else {
          dispatch({ type: "CLEAR_CART" });
          setQtty(0);
          setTotalQuantity(0);
          setShowCart(false);
          toast.success("Votre panier a été mis à jour");
          navigate("/my_commandes");
        }
      })
      .catch((error) => {
        console.log("error is ", error);
      })
      .then(() => {
        setShowCart(false);
      });
  };

  const {
    data: userData,
    isSuccess: isSuccessUserData,
    refetch: refetchUserData,
    status: statusUserData,
  }: UseQueryResult<UserModel, Error> = useQuery<UserModel, Error>(
    `userData-${userInfo?.id}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/me/`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      //   enabled: !!userInfo?.id,
      onSuccess: (data) => {
        console.log("userdata success is ", data);
        setUserInfo(data);
      },
      onError: (error) => {
        console.log("userdata error is ", error);
      },
    }
  );

  const {
    data: getPromoCodeData,
    isSuccess: isSuccessGetPromoCode,
    refetch: refetchGetPromoCode,
    status: statusGetPromoCode,
  }: UseQueryResult<GetPromoCodeModel, Error> = useQuery<
    GetPromoCodeModel,
    Error
  >(
    "getPromoCode",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/panier/get_code_promo`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${
              JSON.parse(localStorage.getItem("profile")!).token
            }`,
          },
        }
      );
      return response.data;
    },
    {
      staleTime: 1000 * 5, // 5 second
      cacheTime: 1000 * 5, // 5 second
      onSuccess: (data) => {
        console.log("getPromoCodeData22 success is ", data);
        data.code && setPromoCodeName(data.code);
      },
      onError: (error) => {
        console.log("getPromoCodeData22 error is ", error);
      },
    }
  );

  return (
    <Card
      // className={classes.cart__root}
      elevation={0}
      ref={cartRef as React.RefObject<HTMLDivElement>}
    >
      {totalQuantity > 0 && (
        <div
          style={{
            overflow: "auto",
            maxWidth: "800px",
            // margin: "0 auto",
            margin: isMobileOrTablet ? "0 10px" : "0 auto",
          }}
        >
          {data?.map((card) => (
            <CartCard key={card.id} card={card} />
          ))}
          <div className={classes.cart__total__container}>
            {isSuccessGetPromoCode &&
            getPromoCodeData?.code &&
            promoCodeName ? (
              ReductionComponent({
                getPromoCodeData,
                setPromoCodeName,
              })
            ) : (
              <Typography
                variant="h6"
                className={classes.promo__code__text}
                onClick={() => setYouHaveAPromoCode(!youHaveAPromoCode)}
              >
                Tu as un code promo ?
              </Typography>
            )}

            {youHaveAPromoCode &&
              CheckPromoCodeComponent({
                promoCode: promoCode,
                setPromoCode: setPromoCode,
                setPromoCodeName: setPromoCodeName,
                setYouHaveAPromoCode: setYouHaveAPromoCode,
              })}

            <Typography variant="h6" className="text-tertiary-dark font-body">
              Total de la commande :{" "}
              {getPromoCodeData?.code && promoCodeName
                ? getPromoCodeData.prix_total
                : calculateTotal()}{" "}
              DH
            </Typography>
            <Typography variant="h6" className="text-subtitle">
              + Frais de livraison: {userData?.address.frais_livraison} DH
            </Typography>
            <Typography variant="h6" className="text-primary-dark fs-xxxl">
              Total à payer:{" "}
              {(getPromoCodeData?.code && promoCodeName
                ? getPromoCodeData.prix_total
                : calculateTotal()) + +userData?.address.frais_livraison!}{" "}
              DH
            </Typography>
          </div>

          <Stack
            direction="row"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleCommandeClick();
              }}
              style={{
                margin: "1rem",
                color: "white",
                width: "100%",
                backgroundColor: "black",
              }}
            >
              <AddShoppingCartIcon />
              <Typography className={classes.cart__commande__text} variant="h6">
                {"valider (" + totalQuantity + ")"}
              </Typography>
            </Button>
          </Stack>
        </div>
      )}

      {/* {totalQuantity === 0 && (
        <div
          style={{
            textAlign: "center",
            margin: "1rem",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ShoppingBagRoundedIcon style={{ fontSize: "5rem" }} />
          <Typography variant="h5" style={{ fontWeight: "300" }}>
            Votre panier est vide
          </Typography>
          <Button
            variant="contained"
            style={{
              backgroundColor: "red",
              color: "white",
              margin: "1rem",
              marginTop: "2rem",
              width: "85%",
              borderRadius: "10px",
            }}
          >
            
            <Typography variant="h5" style={{ fontWeight: "300" }}>
              Continuer mes achats
            </Typography>
          </Button>
        </div>
      )} */}
    </Card>
  );
};
