import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProductSimailireCard } from "./ProductSimailireCard";
import { ProduitsDetailsSimilaire } from "../../../../types/productDetailsModels/productDetailsModel";
import useStyles from "./styles";

interface propsInterface {
  productsSimailire: ProduitsDetailsSimilaire[];
  setColorId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const ProductSimailireList = ({
  productsSimailire,
  setColorId,
}: propsInterface) => {
  const classes = useStyles();

  return (
    <Stack direction="column" spacing={2} padding={6} className={classes.root}>
      <h4 className="text-title text-center m-0 p-0">PRODUCTS SIMAILIRE</h4>

      <Stack direction="row" className={classes.productsCardsContainer}>
        {productsSimailire?.map((item, index) => {
          return (
            <ProductSimailireCard
              key={index}
              {...item}
              setColorId={setColorId}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
