import React from "react";
import AccessoriesAndMoreSection from "./AccessoriesAndMoreSection/AccessoriesAndMoreSection";
import BestSellingProducts from "./bestSellingProducts/BestSellingProducts";
import Carousel from "./Carousel/carousel";
import LatstNewsAndBlogs from "./LatstNewsAndBlogs/LatstNewsAndBlogs";
import PopularCollections from "./PopularCollections/PopularCollections";
import { ShopOurBrands } from "./ShopOurBrands/ShopOurBrands";

const HomePageScreen = () => {
  return (
    <div>
      <Carousel />
      <PopularCollections />
      <BestSellingProducts />
      <AccessoriesAndMoreSection />
      {/* <ShopOurBrands />
      <LatstNewsAndBlogs /> */}
    </div>
  );
};

export default HomePageScreen;
