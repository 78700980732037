import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { MenyType } from "../../types/menuTypes/menuTypes";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useStateContext } from "../../context/stateContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { LastChild } from "./LastChild";
<MenuIcon />;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "40px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

type MenuButtonPropsInterface = {
  image: string;
  elements: MenyType;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

// LastChildModel

export default function MenuButton({
  image,
  elements,
  setOpenMenu,
}: MenuButtonPropsInterface) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const { isPromoIsNew, setIsPromoIsNew } = useStateContext();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");

  const children: MenyType = elements || [];
  const [dropdown, setDropdown] = React.useState(false);
  const [subChildMenu, setSubChildMenu] = React.useState(-1);

  return (
    <div>
      <div style={{ display: "flex", listStyle: "none" }}>
        <li
          style={{
            paddingTop: "22px",
            position: "relative",
            display: isMobileOrTablet ? "flex" : "block",
            flexDirection: isMobileOrTablet ? "column" : "row",
            alignItems: isMobileOrTablet ? "start" : "none",
            width: isMobileOrTablet ? "100vw" : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",

              cursor: "pointer",
              justifyContent: isMobileOrTablet ? "space-between" : "center",
              width: isMobileOrTablet ? "97vw" : "auto",
            }}
            onClick={
              () => isMobileOrTablet && setDropdown(!dropdown)
              // alert("hello world");
            }
          >
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              sx={{
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  borderBottomColor: "black",
                  borderBottomWidth: 1,
                  // borderBottomStyle: "solid",
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  color: "black",
                },
              }}
              onMouseEnter={() => setDropdown(true)}
              onMouseLeave={() => setDropdown(false)}
            >
              <h2 className="menu-button-text">{elements.nom}</h2>
            </Button>

            {dropdown ? (
              <KeyboardArrowDownIcon
                style={{
                  width: isMobileOrTablet ? "25px" : "15px",
                  color: "#000",
                }}
              />
            ) : (
              <KeyboardArrowRight
                style={{
                  width: isMobileOrTablet ? "25px" : "15px",
                  color: "#000",
                }}
              />
            )}
          </div>
          {isMobileOrTablet && dropdown && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                backgroundOrigin: "green",
                // maxHeight: "60vh",
                // overflowY: "scroll",
              }}
            >
              {children.children &&
                children.children.map((child, image) => (
                  <Stack
                    direction="column"
                    spacing={1}
                    key={child.id}
                    style={{
                      backgroundColor: "white",
                      alignItems: "start",
                      justifyContent: "start",
                      marginTop: "15px",
                      marginLeft: "22px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        width: "90vw",
                      }}
                      onClick={() => {
                        setDropdown(true);
                        setSubChildMenu(
                          subChildMenu === child.id ? -1 : child.id
                        );
                        // alert("hello world");
                      }}
                    >
                      <h2 className="big-text text-primary-dark">
                        {child.nom[0].toUpperCase() + child.nom.slice(1)}
                      </h2>
                      {subChildMenu === child.id ? (
                        <KeyboardArrowDownIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#444",
                          }}
                        />
                      ) : (
                        <KeyboardArrowRight
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#444",
                          }}
                        />
                      )}
                    </div>
                    {subChildMenu === child.id && (
                      <div>
                        <LastChild
                          child={child}
                          setDropdown={setDropdown}
                          dropdown={dropdown}
                          parentId={child.id}
                          setOpenMenu={setOpenMenu}
                        />
                      </div>
                    )}
                  </Stack>
                ))}
              {/* <img className={classes.menu__image} alt="logo" src={image} /> */}
            </div>
          )}

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            <Stack spacing={2} sx={{ width: 300 }}>
              {children.children.map((child) => (
                <Link
                  to={`/category/${child.id}`}
                  // className={classes.navbar__link}
                  onClick={() => setDropdown(false)}
                >
                  <Typography
                    variant="h5"
                    style={{ color: "red" }}
                    className={classes.menu__title}
                  >
                    {child.nom}
                  </Typography>
                </Link>
              ))}
            </Stack>
          </StyledMenu>
          {!isMobileOrTablet && dropdown && (
            <div
              className="menu-dropdown"
              style={{
                position: isMobileOrTablet ? "fixed" : "absolute",
                height: isMobileOrTablet ? "40vh" : "auto",
              }}
              onMouseEnter={() => {
                setDropdown(true);
              }}
              onMouseLeave={() => setDropdown(false)}
            >
              <ul style={{ display: "flex", listStyle: "none" }}>
                <div
                  className={classes.menu__stack}
                  style={{ marginTop: "20px" }}
                >
                  {children.children &&
                    children.children.map((child, image) => (
                      <Stack
                        direction="column"
                        spacing={1}
                        key={child.id}
                        style={{ alignItems: "start", justifyContent: "start" }}
                      >
                        <h2 className="big-text text-primary-dark ">
                          {child.nom[0].toUpperCase() + child.nom.slice(1)}
                        </h2>
                        <LastChild
                          child={child}
                          // handleClose={handleClose}
                          setDropdown={setDropdown}
                          dropdown={dropdown}
                          parentId={child.id}
                          setOpenMenu={setOpenMenu}
                        />
                      </Stack>
                    ))}
                  <img className={classes.menu__image} alt="logo" src={image} />
                </div>
              </ul>
            </div>
          )}
        </li>
      </div>
    </div>
  );
}
