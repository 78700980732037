import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDLmrPak6vYNLPHUqvy6Ir4fg887IsAJPI",
  authDomain: "fetnes-store.firebaseapp.com",
  projectId: "fetnes-store",
  storageBucket: "fetnes-store.appspot.com",
  messagingSenderId: "674239180549",
  appId: "1:674239180549:web:dcb09ea2905db8419a516c",
  measurementId: "G-L3CGXWP0LJ",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenM = (
  setTokenFound: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return getToken(messaging)
    .then((token: string) => {
      // console.log(token);
      setTokenFound(true);
    })
    .catch((err: any) => {
      console.log(err);
    })
    .finally(() => {
      // console.log("getToken finally");
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
