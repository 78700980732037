import React from "react";
import { Navbar } from "./components";

// !!! router !!!
import { Routes, Route, useNavigate } from "react-router-dom";
// !!! axios !!!

import HomePageScreen from "./components/HomePage";
import Collections from "./components/Collections";
import { ProductDetails } from "./components/Products/ProductDetails/ProductDetails";
import LoginPage from "./components/LoginPage/loginPage";
import { StateContext } from "./context/stateContext";
import { Toaster } from "react-hot-toast";
import { Cart } from "./components/Cart/cart";
import { Commandes } from "./components/Commandes/Commandes";
import { ProfilePage } from "./components/Profile/ProfilePage";

import { useState } from "react";
import { Button, Row, Col, Toast } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { getTokenM, onMessageListener } from "./firebase";
import { Favoris } from "./components/Favoris/Favoris";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Footer from "./components/Footer";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// scroll to top
import ScrollToTop from "react-scroll-to-top";
import { PrivacePolicy } from "./components/PrivacyPolicy/PrivacePolicy";
import { TermsOfUse } from "./components/TermsOfUse/TermsOfUse";
import { Basket } from "./components/Basket/basket";

// client id      :     925433749309-09fmjr66o3urngiocpega5cc5bf8r8to.apps.googleusercontent.com
// secret code    :     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

const clientId =
  "925433749309-09fmjr66o3urngiocpega5cc5bf8r8to.apps.googleusercontent.com";

export type ProduitModel = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ResultModel[];
};

export type ResultModel = {
  id: number;
  nom: string;
  ordre: any;
  photo_principal: string;
  prix: number;
  nombre_couleurs: number;
  couleurs: CouleurModel[];
  categorie: number;
  promotion: any;
  is_new: boolean;
  favoris: boolean;
  longeur_par_largeur: number;
  nombre_photo_carroussel: number;
};

export type CouleurModel = {
  id: number;
  code: string;
  nom: string;
};

const App = () => {
  //! fetch api produit

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    getTokenM(setTokenFound);
  });

  onMessageListener()
    .then((payload: any) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      // navigations to the cart page
      navigate("my_commandes");
    })
    .catch((err) => console.log("failed: ", err));

  // inside the jsx being returned:

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <StateContext>
        <Cart />
        <Navbar />
        <Toaster />
        <Routes>
          <Route path="/" element={<HomePageScreen />} />
          <Route path="collections/:id/:childId" element={<Collections />} />
          <Route path="product_details/:id" element={<ProductDetails />} />
          <Route path="*" element={<HomePageScreen />} />
          <Route path="login" element={<LoginPage navigateTo="/" />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="profile_page/:name" element={<ProfilePage />} />
          <Route path="basket" element={<Basket />} />
          <Route path="my_commandes" element={<Commandes />} />
          <Route path="favoris" element={<Favoris />} />
          <Route path="privacy_policy" element={<PrivacePolicy />} />
          <Route path="terms_of_use" element={<TermsOfUse />} />
        </Routes>
        <ScrollToTop
          smooth
          viewBox="0 0 24 24"
          style={{
            fill: "#000000",
            width: "50px",
            height: "50px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
          color="16aae0"
          svgPath="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609"
        />
        <Footer />
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          animation
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            zIndex: 9999,
          }}
        >
          <Toast.Header style={{ justifyContent: "space-between" }}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <div style={{ width: "1%" }}></div>
            <div>
              <small>just now</small>
            </div>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
        <CookieConsent
          location="bottom"
          buttonText="Accepter"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Nous utilisons des cookies pour vous garantir la meilleure expérience
          <span style={{ fontSize: "10px" }}></span>
        </CookieConsent>
      </StateContext>
    </QueryClientProvider>
  );
};

export default App;
