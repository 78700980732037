import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  popular__collections__items__title__6: {
    marginLeft: "200px",
  },
  popular__collections__items__title__3: {
    marginLeft: "50px",
  },
  popular__collections__items__title__63: {
    marginLeft: "180px",
  },
}));
