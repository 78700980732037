import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  title: {
    marginTop: "1rem",
    marginBottom: "1.4rem",
  },
  paginationRoot: {
    marginTop: "1rem",
    marginBottom: "1.4rem",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  details_bar__container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subchild__list__container: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    whiteSpace: "nowrap",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    gap: "1.4rem",
    marginLeft: "25.4%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5px",
      marginRight: "5px",
    },
  },
  filter__container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));
