import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SortIcon from "@mui/icons-material/Sort";
import useStyles from "./styles";
import Typography from "@mui/material/Typography";

type MenuOptionsProps = {
  setTrierPar: React.Dispatch<React.SetStateAction<string>>;
  treirPar: string;
};

export default function MenuTrierPar({
  setTrierPar,
  treirPar,
}: MenuOptionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{ whiteSpace: "nowrap", textTransform: "none" }}
        onClick={handleClick}
      >
        <SortIcon className={classes.sort__icon} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ marginTop: "40px", marginLeft: "-20px" }}
      >
        <MenuItem
          onClick={() => {
            setTrierPar("");
            handleClose();
          }}
          style={treirPar === "" ? { backgroundColor: "#f0f0f0" } : {}}
        >
          <h4 className="order-by-buttons-text">Recommandés</h4>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTrierPar("created_at");
            handleClose();
          }}
          style={
            treirPar === "created_at" ? { backgroundColor: "#f0f0f0" } : {}
          }
        >
          <h4 className="order-by-buttons-text">Nouveautés</h4>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTrierPar("prix");
            handleClose();
          }}
          style={treirPar === "prix" ? { backgroundColor: "#f0f0f0" } : {}}
        >
          <h4 className="order-by-buttons-text">Prix croissant</h4>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTrierPar("-prix");
            handleClose();
          }}
          style={treirPar === "-prix" ? { backgroundColor: "#f0f0f0" } : {}}
        >
          <h4 className="order-by-buttons-text">Prix décroissant</h4>
        </MenuItem>
      </Menu>
    </div>
  );
}
