import React from "react";
import { FavorisContainer } from "./FavorisContainer";
import useStyles from "./styles";

export const Favoris = () => {
  const classes = useStyles();
  return (
    <div className={classes.Favoris__container}>
      <FavorisContainer />
    </div>
  );
};
