import React from "react";
import Skeleton from "@mui/material/Skeleton";

export const LoadingCollectionsProductsFilter = () => {
  return (
    <div>
      <LoadingCollectionsProductsFilterCart />
      <LoadingCollectionsProductsFilterCart />
      <LoadingCollectionsProductsFilterCart />
    </div>
  );
};

export const LoadingCollectionsProductsFilterCart = () => {
  return (
    <div>
      <Skeleton variant="rectangular" height={50} />
      <Skeleton
        variant="rectangular"
        height={20}
        width="70%"
        style={{ margin: "10px 0", marginLeft: "10px" }}
      />
      {Array.from({ length: 12 }, (_, i) => (
        <span key={i}>
          <Skeleton
            variant="rectangular"
            height={20}
            width="70%"
            style={{ margin: "10px 0", marginLeft: "10px" }}
          />
        </span>
      ))}
    </div>
  );
};
