/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { FilterCategories } from "../../../types/productsList/FilterCategoriesModel";
import { FilterCategoryGroupModelList } from "../../../types/FilterModels/FilterModel";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

type FilterCheckBoxProps = {
  filterCategories: FilterCategories;
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<FilterCategoryGroupModelList>
  >;
  selectedFilter: FilterCategoryGroupModelList;
};

const FormCheckBox = ({
  filterCategories,
  setSelectedFilter,
  selectedFilter,
}: FilterCheckBoxProps) => {
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");
  const [expanded, setExpanded] = React.useState<string | false>(false);
  // const [expanded, setExpanded] = React.useState<string | false>(
  //   isMobileOrTablet ? false : "panel1"
  // );

  useEffect(() => {
    setExpanded(isMobileOrTablet ? false : "panel1");
  }, [isMobileOrTablet]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <h3 className="text-body mt-2">
          {filterCategories &&
            filterCategories.nom[0].toUpperCase() +
              filterCategories.nom.slice(1).toLowerCase()}
        </h3>
      </AccordionSummary>
      <Box sx={{ display: "flex" }}>
        <FormControl
          sx={{ m: 3, color: "text.secondary", width: "100%" }}
          component="fieldset"
          variant="standard"
          size="small"
        >
          <FormGroup
            onChange={(e) => {
              const targetName = e.target as HTMLInputElement;

              var FilterName = false;

              selectedFilter.map((filter) => {
                if (filter.nom === filterCategories.nom) {
                  FilterName = true;
                  return filter;
                }
              });

              if (FilterName === false) {
                selectedFilter.push({
                  nom: filterCategories.nom,
                  items: [parseInt(targetName.name)],
                });
              }

              FilterName &&
                selectedFilter.map((filter) => {
                  if (filter.nom === filterCategories.nom) {
                    if (
                      filter.items.indexOf(parseInt(targetName.name)) === -1
                    ) {
                      filter.items.push(parseInt(targetName.name));
                    } else {
                      filter.items.splice(
                        filter.items.indexOf(parseInt(targetName.name)),
                        1
                      );
                    }
                  }
                });

              selectedFilter.map((filter) => {
                if (filter.items.length === 0) {
                  selectedFilter.splice(selectedFilter.indexOf(filter), 1);
                }
              });

              setSelectedFilter([...selectedFilter]);
            }}
          >
            {filterCategories.attributs.map((option) => {
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  key={option.id}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {}}
                        name={option.id.toString()}
                        color="default"
                      />
                    }
                    label={
                      <h3 className="text-body mt-2">
                        {option.nom[0].toUpperCase() +
                          option.nom.slice(1).toLowerCase()}
                      </h3>
                    }
                  />
                  {/* <span className="text-muted">(13)</span> */}
                </Stack>
              );
            })}
          </FormGroup>
          {/* <Divider style={{ margin: "1rem 0" }} /> */}
        </FormControl>
      </Box>
    </Accordion>
  );
};

export default FormCheckBox;
