import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SortIcon from "@mui/icons-material/Sort";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import {
  Card,
  CardMedia,
  Typography,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { AccessoriesAndMoreSectionCard } from "./AccessoriesAndMoreSectionCard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,

  height: "100%",
  textAlign: "center",
  color: theme.palette.text.secondary,
  overflow: "hidden",
  cursor: "pointer",

  img: {
    width: "100%",
    height: "100%",
    // full height of the item
    objectFit: "cover",
    objectPosition: "center",
  },
  //hover effect
  "&:hover": {
    img: {
      transform: "scale(1.01)",
      transition: "all 0.5s ease-in-out",
    },
  },
}));

export default function AccessoriesAndMoreSection() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const isLargeScreen = useMediaQuery("min-width: 1270px");
  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: "1470px",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "2rem",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          height={isMobile ? "100vw" : "35vw"}
          maxHeight="490px"
          pr={0.5}
        >
          <Item>
            <AccessoriesAndMoreSectionCard
              image="https://images.wunderweib.de/istock-1057872152,id=9ee506a2,b=wunderweib,w=1600,h=,ca=0,7.81,100,92.19,rm=sk.jpeg"
              title="NOTRE MAGAZINS"
              id={61}
            />
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          height={isMobile ? "100vw" : "35vw"}
          maxHeight="490px"
          pl={0.5}
        >
          <Item>
            <AccessoriesAndMoreSectionCard
              image="http://youthopia.in/wp-content/uploads/2019/10/shopaholic-1.jpg"
              title="ACHATS"
              id={182}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
