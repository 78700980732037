import { AUTH, LOGOUT } from "../../constants/actionTypes";

const authReducer = (state = { authData: null }, action: any) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      return {
        ...state,

        isAuthenticated: true,
        authData: action?.data,
      };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        imageUrl: null,
        givenName: null,
        familyName: null,
        email: null,
        isAuthenticated: false,
        authData: {},
      };
    default:
      return state;
  }
};

export default authReducer;
