import { Typography, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import CollectionsProductsFilter from "./CollectionsProductsFilter";
import CollectionsProductsList from "./CollectionsProductsList";
import { useParams } from "react-router-dom";
import { FilterCategoryGroupModelList } from "../../types/FilterModels/FilterModel";
import useStyles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuTrierPar from "./TrierPar";
import { useStateContext } from "../../context/stateContext";
import { LastChildModel } from "../../types/LastChild/lastChild";
import { useNavigate } from "react-router-dom";
import { NewMenuModel } from "../../types/menuTypes/menuTypes";
import { PromotionActifModel } from "../../types/promotionActifModel/promotionActifModel";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { IsNewIsPromoModel } from "../../types/IsNewIsPromoModel/IsNewIsPromoModel";
import { isPromoisNewModel } from "../../types/IsPromoIsNew/isPromoIsNewModel";

const Collections = () => {
  const { id: userId, childId } = useParams();
  const { isPromoIsNew, setIsPromoIsNew } = useStateContext();
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] =
    React.useState<FilterCategoryGroupModelList>([]);
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");
  const [filterColor, setFilterColor] = React.useState<string[]>([]);
  const [treirPar, setTrierPar] = React.useState<string>("");

  const {
    data: dataLastChild,
    status: statusLastChild,
    refetch: refetchLastChild,
    isFetching: isFetchingLastChild,
    isLoading: isLoadingLastChild,
  } = useQuery<LastChildModel[], Error, LastChildModel[], string>(
    `lastChild${childId}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/menu/${childId}/last_childs/`
      );
      return [
        {
          nom: "Tous",
          id: childId as unknown as number,
          photo_principal: "",
          photo_secondaire: "",
        } as LastChildModel,
        ...response.data,
      ];
    },

    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
      // enabled: childId !== "new" && childId !== "promotion",
    }
  );

  useEffect(() => {
    refetchLastChild();
    // refetchNew();
    // refetchPromotion();
    refetchNouvelleCollection_isPromotion();
  }, [childId]);

  const {
    data: isNouvelleCollection_isPromotion,
    status: statusNouvelleCollection_isPromotion,
    refetch: refetchNouvelleCollection_isPromotion,
    isFetching: isFetchingNouvelleCollection_isPromotion,
    isLoading: isLoadingNouvelleCollection_isPromotion,
  } = useQuery<IsNewIsPromoModel, Error, IsNewIsPromoModel, string>(
    `isNewIsPromo${childId}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/menu/${childId}/is_new_is_promotion/`
      );
      return response.data;
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
      onSuccess: (data) => {
        console.log("IsNewIsPromo ---> ", data);
      },
      onError: (error) => {
        console.log("IsNewIsPromo ---> ", error);
      },
    }
  );

  return (
    <div style={{ maxWidth: "1470px", margin: "0 auto" }}>
      <div className={classes.details_bar__container}>
        <div className={classes.subchild__list__container}>
          {statusNouvelleCollection_isPromotion === "success" &&
            isNouvelleCollection_isPromotion.is_new && (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  backgroundColor: "#C7F5EB",
                  borderRadius: "5px",
                  padding: "2px 10px",
                }}
                onClick={() => {
                  setIsPromoIsNew({
                    isPromo: "",
                    isNew: "t",
                  });

                  navigate(`/collections/${childId}/${childId}`);
                }}
                // key={id}
              >
                <span
                  className="text-subtitle2"
                  style={{
                    color: "#0E8161",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  {"NOUVEAU"}
                </span>
              </div>
            )}
          {statusLastChild === "success" &&
            dataLastChild?.map(({ id, nom }, index) => (
              <Typography
                variant="body1"
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  color: userId === id?.toString() ? "#25AFE2" : "#fff",
                }}
                onClick={() => {
                  navigate(`/collections/${id}/${childId}`);
                  setIsPromoIsNew({
                    isPromo: "",
                    isNew: "",
                  });
                }}
                key={id}
              >
                <span
                  className="text-subtitle2"
                  style={{
                    color:
                      userId === id?.toString() &&
                      !isPromoIsNew.isNew.length &&
                      !isPromoIsNew.isPromo.length
                        ? "#25AFE2"
                        : "grey",
                  }}
                >
                  {nom.toUpperCase()}
                </span>
              </Typography>
            ))}
          {statusNouvelleCollection_isPromotion === "success" &&
            isNouvelleCollection_isPromotion.is_promotion && (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  backgroundColor: "#FFE5F4",
                  borderRadius: "5px",
                  padding: "2px 10px",
                }}
                onClick={() => {
                  setIsPromoIsNew({
                    isPromo: "t",
                    isNew: "",
                  });

                  navigate(`/collections/${childId}/${childId}`);
                }}
                // key={id}
              >
                <span
                  className="text-subtitle2"
                  style={{
                    color: "#E46BB2",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  {"PROMOTION"}
                </span>
              </div>
            )}
        </div>
        {!isMobileOrTablet && (
          <MenuTrierPar treirPar={treirPar} setTrierPar={setTrierPar} />
        )}
      </div>

      <Grid container spacing={2}>
        <Grid item xs={isMobileOrTablet ? 12 : 3}>
          <div
            style={{
              position: "relative",
              top: "0",
              marginTop: isMobileOrTablet ? "0" : "-28px",
              width: "100%",
            }}
          >
            {" "}
            {isMobileOrTablet && (
              <div style={{ position: "absolute", top: "0", right: "0" }}>
                <MenuTrierPar treirPar={treirPar} setTrierPar={setTrierPar} />
              </div>
            )}
            <CollectionsProductsFilter
              listId={userId ?? "1"}
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
              setFilterColor={setFilterColor}
              filterColor={filterColor}
              setTrierPar={setTrierPar}
              treirPar={treirPar}
            />
          </div>
        </Grid>
        <Grid item xs={isMobileOrTablet ? 12 : 9}>
          <CollectionsProductsList
            listId={userId ?? "1"}
            selectedFilter={selectedFilter}
            filterColor={filterColor}
            setTrierPar={setTrierPar}
            treirPar={treirPar}
          />
        </Grid>
        <div style={{ height: "21px", width: "100%" }}></div>

        {/* <PaginationControlled /> */}
      </Grid>
    </div>
  );
};

export default Collections;
