import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  cart__container: {
    position: "fixed",
    top: "0",
    right: "0",
    width: "30%",
    height: "100%",
    zIndex: 9999,
    // backgroundColor: "red",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  card: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    padding: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  card__info: {
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  cardAttributes: {
    color: "gray",
    marginBottom: "2rem",
  },
  cardQuantity: {
    marginTop: "1rem",
    marginBottom: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  //   backgroundColor: "#F5F5F5", color: "#000"
  quantityButton: {
    backgroundColor: "red",
    color: "#000",
  },
  cart__root: {
    position: "absolute",
    zIndex: 4000,
    right: "0",
    width: "650px",
  },
  [theme.breakpoints.down("sm")]: {
    cart__root: {
      width: "100%",
    },
  },
  cart__return__text: {
    fontSize: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cart__article__text: {},
  card__image: {
    width: "220px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  card__price__delete: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  commander__button__text: {
    fontSize: "1.2rem",
  },
  cart__total__container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "0 1rem",
  },
  promo__code__text: {
    fontSize: "0.8rem",
    color: "orange",
    cursor: "pointer",
  },
  promo__code__input: {
    width: "100%",
    color: "black",
  },
  promo__code__button: {
    width: "100%",
    height: "2rem",
    marginBottom: "1rem",
    backgroundColor: "black",
    color: "white",
  },
  promo__input__button__container: {
    display: "flex",
    width: "100%",
  },
}));
