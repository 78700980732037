import { Box, Typography, useMediaQuery, Grid, Paper } from "@material-ui/core";
import Rating from "@mui/material/Rating";
import useStyles from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { styled } from "@mui/material/styles";
import BestSellingProductCard from "./BestSellingProductCard";
import { ProductModel } from "../../../types/productsList/productsLIst";
import { useEffect, useState } from "react";
// import css file
import "./styles.css";

const BestSellingProducts = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const isLaptop = useMediaQuery("(max-width:1280px)");
  const isDesktop = useMediaQuery("(max-width:1920px)");
  const isWidescreen = useMediaQuery("(max-width:2560px)");

  const [productsList, setProductsList] = useState<
    ProductModel[] | "error" | "loading"
  >("loading");

  useEffect(() => {
    const requestOptions = {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(
      process.env.REACT_APP_API_URL + `/api/produit?categorie=63`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setProductsList(data.results as ProductModel[]);
      })
      .catch((error) => {
        setProductsList("error");
        console.log("error is ", error);
      });
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <h4 className="text-title text-center" style={{ zIndex: 9999 }}>
        {/* BestSellingProducts */}
        BEST SELLING PRODUCTS
      </h4>
      <div
        style={{
          maxWidth: "1470px",
          maxHeight: "60%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "auto",
          marginTop: "-1.7rem",
          zIndex: -1,
          height: "100%",

          // overflow: "hidden",

          // backgroundColor: "red",
        }}
      >
        <Swiper
          slidesPerView={
            isMobile
              ? 1
              : isTablet
              ? 2
              : isLaptop
              ? 4
              : isDesktop
              ? 5
              : isWidescreen
              ? 5
              : 6
          }
          spaceBetween={30}
          slidesPerGroup={
            isMobile
              ? 1
              : isTablet
              ? 2
              : isLaptop
              ? 4
              : isDesktop
              ? 5
              : isWidescreen
              ? 5
              : 6
          }
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className={classes.swiper}
        >
          {productsList === "loading" ? (
            <div>loading</div>
          ) : productsList === "error" ? (
            <div>error</div>
          ) : (
            productsList.map((product) => (
              <SwiperSlide>
                <BestSellingProductCard
                  image={product.photo_principal}
                  name={product.nom}
                  price={product.prix}
                  id={product.id}
                  rating={5}
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
    </Box>
  );
};

export default BestSellingProducts;
