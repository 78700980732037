import React from "react";

export const PrivacePolicy = () => {
  return (
    <div style={{ width: "100vw" }}>
      <h1 className="text-center footer-title" style={{ marginTop: "2rem" }}>
        Politique de confidentialité
      </h1>

      <div style={{ marginTop: "6rem", maxWidth: "1470px", margin: "auto" }}>
        <p className="text-body1">
          Cette politique de confidentialité a été mise à jour le 19 septembre
          2022.
        </p>
        <p className="text-body1">
          Nous sommes très sensibles à la protection de vos données
          personnelles. Nous nous engageons à respecter la confidentialité de
          vos données personnelles et à les protéger contre tout accès non
          autorisé. Nous nous engageons à respecter la loi sur la protection des
          données personnelles et à respecter les principes de confidentialité
          et de sécurité des données personnelles.
        </p>
        <p className="text-body1">
          Nous vous informons que nous collectons et traitons vos données
          personnelles conformément à la loi sur la protection des données
          personnelles et à la présente politique de confidentialité.
        </p>
      </div>

      <div style={{ marginTop: "2rem", maxWidth: "1470px", margin: "auto" }}>
        <h2 className="text-subtitle2">Qui sommes-nous ?</h2>
        <p className="text-body1">
          Nous sommes une société de commerce électronique qui vend des vitments
          et des accessoires de mode.
        </p>

        <h2 className="text-subtitle2">
          Quelles données personnelles collectons-nous ?
        </h2>
        <p className="text-body1">
          Nous collectons les données personnelles suivantes :
        </p>
        <ul>
          <li className="text-body1">Nom et prénom</li>
          <li className="text-body1">Adresse e-mail</li>
          <li className="text-body1">Numéro de téléphone</li>
          <li className="text-body1">Adresse postale</li>
          <li className="text-body1">Informations de navigation</li>
        </ul>

        <h2 className="text-subtitle2">
          Pourquoi collectons-nous vos données personnelles ?
        </h2>
        <p className="text-body1">
          Nous collectons vos données personnelles pour les raisons suivantes :
        </p>
        <ul>
          <li className="text-body1">Pour vous fournir nos services</li>
          <li className="text-body1">Pour vous contacter</li>
          <li className="text-body1">
            Pour vous envoyer des offres commerciales
          </li>
          <li className="text-body1">
            Pour vous envoyer des offres commerciales
          </li>
        </ul>

        <h2 className="text-subtitle2">
          Comment collectons-nous vos données personnelles ?
        </h2>
        <p className="text-body1">
          Nous collectons vos données personnelles de la manière suivante :
        </p>
        <ul>
          <li className="text-body1">Directement auprès de vous</li>
          <li className="text-body1">Via des tiers</li>
        </ul>

        <h2 className="text-subtitle2">
          Comment utilisons-nous vos données personnelles ?
        </h2>
        <p className="text-body1">
          Nous utilisons vos données personnelles pour les raisons suivantes :
        </p>
        <ul>
          <li className="text-body1">Pour vous fournir nos services</li>
          <li className="text-body1">Pour vous contacter</li>
          <li className="text-body1">
            Pour vous envoyer des offres commerciales
          </li>
        </ul>

        <h2 className="text-subtitle2">
          Avec qui partageons-nous vos données personnelles ?
        </h2>

        <p className="text-body1">
          Nous ne partageons pas vos données personnelles avec des tiers.
        </p>

        <h2 className="text-subtitle2">
          Combien de temps conservons-nous vos données personnelles ?
        </h2>

        <p className="text-body1">
          Nous conservons vos données personnelles pendant 5 ans.
        </p>

        <h2 className="text-subtitle2">
          Comment protégeons-nous vos données personnelles ?
        </h2>

        <p className="text-body1">
          Nous prenons toutes les mesures nécessaires pour protéger vos données
          personnelles contre toute perte, utilisation abusive, accès non
          autorisé, divulgation, modification ou destruction.
        </p>

        <h2 className="text-subtitle2">Quels sont vos droits ?</h2>

        <p className="text-body1">
          Vous avez le droit de demander l'accès à vos données personnelles, de
          les rectifier ou de les supprimer. Vous avez également le droit de
          vous opposer à l'utilisation de vos données personnelles, de demander
          la limitation du traitement de vos données personnelles et de demander
          la portabilité de vos données personnelles. Vous pouvez également
          retirer votre consentement à tout moment. Vous pouvez exercer ces
          droits en nous contactant par e-mail à l'adresse suivante :
          <a href="mailto: contact@icebergnet.net" className="text-link" />
        </p>

        <h2 className="text-subtitle2">Comment déposer une réclamation ?</h2>

        <p className="text-body1">
          Vous avez le droit de déposer une réclamation auprès de la Commission
          nationale de l'informatique et des libertés (CNIL) si vous estimez que
          le traitement de vos données personnelles viole la loi sur la
          protection des données personnelles.
        </p>

        <h2 className="text-subtitle2">
          Modifications de la présente politique de confidentialité
        </h2>

        <p className="text-body1">
          Nous nous réservons le droit de modifier cette politique de
          confidentialité à tout moment. Les modifications seront publiées sur
          cette page.
        </p>
      </div>
    </div>
  );
};
