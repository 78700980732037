import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
  },

  priceContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },

  priceText: {
    fontSize: "1.2rem",
  },

  taxText: {
    fontSize: "0.9rem",
  },

  reviewsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  stockEmpty: {
    color: "black",
  },

  through__price: {
    textDecoration: "line-through",
    color: "#9e9e9e",
                      marginRight: "10px",
  },
}));
