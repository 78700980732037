import React from "react";
import { LoadingProductCard } from "./LoadingProductCard";

export const LoadingProductCardContainer = () => {
  return (
    <div>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
        <LoadingProductCard key={item} />
      ))}
    </div>
  );
};
