import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { ProductDetailsCouleur2 } from "../../../../types/productDetailsModels/productDetailsModel";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

interface propsInterface {
  setColorId: React.Dispatch<React.SetStateAction<number | null>>;
  nom: string;
  photo_principal: string;
  id: number;
  prix: number;
  couleurs: ProductDetailsCouleur2[];
}

export const ProductSimailireCard = (props: propsInterface) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { nom, photo_principal, id, prix, couleurs, setColorId } = props;

  return (
    <Card
      className={classes.cardRoot}
      onClick={() => {
        // setColorId(null);
        // window.location.reload();
        window.scrollTo(0, 0);
        window.location.href = `/product_details/${id}`;
        navigate(`/product_details/${id}`);
      }}
    >
      <CardActionArea>
        <Link
          style={{
            textDecoration: "none",
          }}
          to={`/product_details/${id}`}
        >
          <CardMedia
            onClick={() => {}}
            component="img"
            height="388"
            image={photo_principal}
            alt={nom}
          />
        </Link>

        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign={"center"}
          >
            {nom.substring(0, 18)}
          </Typography>
          <Stack
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
            alignContent={"center"}
            flexDirection={"row"}
          >
            <Typography variant="h6" color="text.secondary">
              {prix} MAD
            </Typography>
            <AvatarGroup sx={{ alignItems: "center", marginBottom: "1rem" }}>
              {couleurs.map((couleur: ProductDetailsCouleur2) => (
                <Avatar
                  key={couleur.id}
                  sx={{ width: 25, height: 25 }}
                  style={{
                    backgroundColor:
                      couleur.code.indexOf("#") === -1
                        ? `#${couleur.code}`
                        : couleur.code,
                    marginRight: "0rem",
                    color:
                      couleur.code.indexOf("#") === -1
                        ? `#${couleur.code}`
                        : couleur.code,
                  }}
                >
                  <Typography></Typography>
                </Avatar>
              ))}
            </AvatarGroup>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
