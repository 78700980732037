import React from "react";
import Typography from "@mui/material/Typography";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { GetPromoCodeModel } from "../../types/PromoCode/getPromoCode";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { userInfoType } from "../../types/user/user";

interface reductionComponentProps {
  getPromoCodeData: GetPromoCodeModel;
  setPromoCodeName: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface UnsetPromoCodeModel {
  prix_total: number;
}

export const ReductionComponent = ({
  getPromoCodeData,
  setPromoCodeName,
}: reductionComponentProps) => {
  //   const {
  //     data,
  //     isSuccess: isSuccessUnsetPromoCode,
  //     refetch: refetchUnsetPromoCode,
  //     status: statusUnsetPromoCode,
  //   }: UseQueryResult<UnsetPromoCodeModel, Error> = useQuery<
  //     UnsetPromoCodeModel,
  //     Error
  //   >(
  //     "unset_code_promo",
  //     async () => {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_API_URL}/api/panier/unset_code_promo/`,
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //             authorization: `Bearer ${
  //               JSON.parse(localStorage.getItem("profile")!).token
  //             }`,
  //           },
  //         }
  //       );
  //       return response.data;
  //     },
  //     {
  //       staleTime: 1000 * 5, // 5 second
  //       cacheTime: 1000 * 5, // 5 second
  //       //   refetchOnWindowFocus: false,
  //       //   retry: 0,

  //       onSuccess: (data) => {
  //         toast.success("code promo supprimé avec succès");
  //       },
  //       onError: (error) => {
  //         toast.error("une erreur est survenue");
  //       },
  //     }
  //   );

  const unsetPromoCode = () => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
      body: JSON.stringify({}),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/panier/unset_code_promo/`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("code promo response is ", data);
        toast.success("code promo supprimer avec succès");
        setPromoCodeName(null);
      })
      .catch((error) => {
        toast.error("une erreur est survenue");
      })
      .then(() => {});
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6" style={{ color: "#16AAE0" }}>
        {getPromoCodeData?.pourcentage_reduction}% de réduction code promo:{" "}
        {getPromoCodeData?.code}
      </Typography>
      <DeleteForeverSharpIcon
        onClick={() => {
          unsetPromoCode();
        }}
        style={{ color: "red", cursor: "pointer" }}
      />
    </div>
  );
};
