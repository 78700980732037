import React, { useEffect, useRef } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useStyles from "./styles";
import { userInfoType } from "../../types/user/user";
import { GetPromoCodeModel } from "../../types/PromoCode/getPromoCode";
import toast from "react-hot-toast";

interface PropsCheckPromoCode {
  promoCode: string;
  setPromoCode: React.Dispatch<React.SetStateAction<string>>;
  setPromoCodeName: React.Dispatch<React.SetStateAction<string | null>>;
  setYouHaveAPromoCode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CheckPromoCodeComponent = ({
  promoCode,
  setPromoCode,
  setPromoCodeName,
  setYouHaveAPromoCode,
}: PropsCheckPromoCode) => {
  //   const classes = useStyles();

  const checkPromoCode = () => {
    const userinfo = localStorage.getItem("profile");
    const convertedUserInfo = JSON.parse(userinfo!) as userInfoType;

    const requestOptions = {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${convertedUserInfo.token}`,
      },
      body: JSON.stringify({
        codepromo: promoCode,
      }),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/panier/check_code_promo/`, // google_sign_in_token,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("code promo response is ", data);
        let promoCodeResponse = data as GetPromoCodeModel;
        if (promoCodeResponse.pourcentage_reduction !== undefined) {
          toast.success(
            `code promo valide -${promoCodeResponse.pourcentage_reduction} sur le total de la commande`
          );
          setPromoCodeName(promoCodeResponse.code);
          setYouHaveAPromoCode(false);
        } else {
          toast.error("code promo invalide");
        }
      })
      .catch((error) => {
        console.log("code promo error is ", error);
      })
      .then(() => {});
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <TextField
        id="outlined-basic"
        onChange={(e) => setPromoCode(e.target.value)}
        variant="outlined"
        label="Code promo"
        placeholder="Copier le code promo ici"
        style={{ width: "100%", marginRight: "10px", color: "black" }}
      />
      <Button
        onClick={checkPromoCode}
        variant="contained"
        style={{
          color: "white",
          width: "50%",
          backgroundColor: "black",
        }}
      >
        Valider le code
      </Button>
    </div>
  );
};
