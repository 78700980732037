import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import CheckIcon from "@mui/icons-material/Check";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import {
  ColoursProductLIstModel,
  ColoursProductLIstModelList,
} from "../../../types/productsList/ColoursProductLIstModel";
import useStyle from "./styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  listId: string;
  setFilterColor: React.Dispatch<React.SetStateAction<string[]>>;
  filterColor: string[];
}

const SelectColorCollections = ({
  listId,
  setFilterColor,
  filterColor,
}: Props) => {
  // const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [coloursList, setColoursList] =
    React.useState<ColoursProductLIstModelList | null>(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    fetch(
      process.env.REACT_APP_API_URL +
        `/api/filtre-couleur/?categorie=${listId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // setVilles(data as VillesModel);
        setColoursList(data as ColoursProductLIstModelList);
        // console.log("api ville is ", data);
      })
      .catch((error) => {
        console.log("error is ", error);
      });
  }, [listId]);

  // const ColoursList = data as ColoursProductLIstModelList;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const [value, setValue] = React.useState<number[]>([0, 100]);

  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <h3 className="text-body mt-2">Select Color</h3>
      </AccordionSummary>
      <Box
        sx={{
          width: "auto",
          marginTop: "1rem",
          marginBottom: "2rem",
          marginLeft: "1rem",
        }}
      >
        <Stack direction="row" flexWrap="wrap" width="100%" gap={1.2}>
          {coloursList?.map((color, index) => (
            <ColorsCircleWithBorder
              key={index}
              color={
                color.code.indexOf("#") === -1 ? `#${color.code}` : color.code
              }
              selected={false}
              setFilterColor={setFilterColor}
              filterColor={filterColor}
              coloursProductModel={color}
            />
          ))}
        </Stack>
      </Box>
    </Accordion>
  );
};

type ColorsCircleWithBorderProps = {
  color: string;
  selected: boolean;
  setFilterColor: React.Dispatch<React.SetStateAction<string[]>>;
  filterColor: string[];
  // ColoursProductLIstModel
  coloursProductModel?: ColoursProductLIstModel;
};

export const ColorsCircleWithBorder = ({
  color,
  selected,
  setFilterColor,
  filterColor,
  coloursProductModel,
}: ColorsCircleWithBorderProps) => {
  const classes = useStyle();
  return (
    <Box
      className={classes.circleBackground}
      sx={{
        backgroundColor: selected ? "rgba(0, 0, 0, 0.9)" : "rgba(0, 0, 0, 0.0)",
        // borderRadius: "50%",
        // width: "2rem",
        // height: "2rem",
        // justifyContent: "center",
        // alignItems: "center",
        // display: "flex",
      }}
    >
      <Avatar
        sx={{ bgcolor: color, width: 37, height: 37 }}
        onClick={() => {
          if (coloursProductModel) {
            if (filterColor.includes(coloursProductModel?.id.toString())) {
              setFilterColor(
                filterColor.filter(
                  (item) => item !== coloursProductModel?.id.toString()
                )
              );
            } else {
              setFilterColor([
                ...filterColor,
                coloursProductModel?.id.toString(),
              ]);
            }
          }

          console.log(coloursProductModel?.id.toString());
        }}
      >
        <Typography>
          {filterColor.includes(coloursProductModel?.id.toString() ?? "") ? (
            <CheckIcon />
          ) : (
            ""
          )}
        </Typography>
      </Avatar>
    </Box>
  );
};

export default SelectColorCollections;
