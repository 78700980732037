import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStateContext } from "../../context/stateContext";
import Avatar from "@mui/material/Avatar";
import useStyles from "./styles";

type MenuOptionsProps = {};

export default function MenuOptions({}: MenuOptionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { setTotalQuantity, userInfo, setUserInfo } = useStateContext();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("login");
    setUserInfo(null);
    localStorage.removeItem("profile");
    localStorage.removeItem("token");
    setTotalQuantity(0);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar
          className={classes.avatar}
          src={userInfo.imageUrl}
          alt={userInfo.givenName}
        >
          {" "}
          {userInfo?.givenName?.charAt(0)}
        </Avatar>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ marginTop: "40px", marginLeft: "-10px" }}
      >
        <MenuItem
          onClick={() => {
            navigate("profile_page/settings");
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("my_commandes");
            handleClose();
          }}
        >
          Commandes
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
