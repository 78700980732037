import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

import ProductDetailsCarousel from "./ProductDetailsCarousel/ProductDetailsCarousel";
import { Box } from "@material-ui/core";
import { ProductDetailsDesription } from "./ProductDetailsDescription/ProductDetailsDesription";
import { FreeShippingAndHassleFreeExchanges } from "./FreeShippingAndHassleFreeExchanges/FreeShippingAndHassleFreeExchanges";
import { HowWeManufactureOurProductsAtHq } from "./HowWeManufactureOurProductsAtHq/HowWeManufactureOurProductsAtHq";
import { HowItsMade } from "./HowItsMade/HowItsMade";
import { ProductDetailsModel } from "../../../types/productDetailsModels/productDetailsModel";
import { ProductSimailireList as ProductsSimailireList } from "./ProductSimailire/ProductSimailireList";
import { useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { LoadingProductDetailsPage } from "./LoadingProductDetailsPage";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";

export const ProductDetails = () => {
  const params = useParams();
  const userId = params.id;
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");

  const [colorId, setColorId] = React.useState<number | null>(null);

  const {
    data,
    status,
    refetch,
    isFetching,
    isLoading,
  }: UseQueryResult<ProductDetailsModel, Error> = useQuery<
    ProductDetailsModel,
    Error,
    ProductDetailsModel,
    string
  >(
    `productDetails${userId}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/produit/${userId}`
      );
      return response.data;
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  useEffect(() => {
    refetch();
  }, [params.id, userId]);

  // const CastedProductDetails =
  //   dataProductDetails as unknown as ProductDetailsModel;

  if (isLoading || isFetching) {
    return (
      <div
        style={{
          maxWidth: "1470px",
          margin: "0 auto",
        }}
      >
        <LoadingProductDetailsPage isMobileOrTablet={isMobileOrTablet} />
      </div>
    );
  }

  if (status === "error") {
    <div className="error_container">
      Quelque chose s'est mal passé, veuillez réessayer plus
    </div>;
  }

  return (
    <>
      {status === "success" && (
        <Box style={{ maxWidth: "1470px", margin: "0 auto" }}>
          {/* <Box sx={{ height: "20vh" }} /> */}
          <Box
            style={{
              marginTop: "10px",
            }}
          />
          <Grid container spacing={0}>
            <Grid item xs={isMobileOrTablet ? 12 : 5}>
              <ProductDetailsCarousel
                productDetails={data}
                productDetailsId={userId}
                setColorId={setColorId}
              />
            </Grid>
            <Grid item xs={isMobileOrTablet ? 12 : 7}>
              <ProductDetailsDesription
                productDetails={data}
                colorId={colorId}
              />
            </Grid>
          </Grid>
          {/* <FreeShippingAndHassleFreeExchanges /> */}
          <ProductsSimailireList
            productsSimailire={data.produits_similaire}
            setColorId={setColorId}
          />
          {/* <HowWeManufactureOurProductsAtHq /> */}
          {/* <HowItsMade /> */}
        </Box>
      )}
    </>
  );
};
