import { makeStyles, alpha } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: { minHeight: "95vh" },
  mGoogleButton: {
    backgroundColor: "red",
    padding: "20px",
    width: "100%",
    height: "44px",
  },

  googleLogin: {
    backgroundColor: "red",
  },

  googleLoginText: {
    fontSize: "12px",
    color: "white",
    padding: "20px",
  },

  facebookLogin: {
    paddingTop: "5px",
    width: "100%",
  },

  copyright: {
    padding: "10px",
  },

  facebookAndGoogleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    marginTop: "20px",
  },

  checkboxAcceptConditionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "3px",
  },

  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  },

  progressCircle: {
    color: "black",
  },
}));
