import { Toolbar } from "@material-ui/core";
import React from "react";
import MenuButton from "./MenuButon";
import useStyles from "./styles";
import axios from "../../apis/my_axios";
import useAxios from "../../hooks/useAxios";
import { MenyType, NewMenuModel } from "../../types/menuTypes/menuTypes";
import MenuIcon from "@mui/icons-material/Menu";
import { Search } from "@material-ui/icons";
import { useMediaQuery } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import NewMenuButton from "./NewMenuButton";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { SearchModel } from "../../types/SearchModels/SearchModel";
import { SearchModal } from "./SearchModal";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import { PromotionActifModel } from "../../types/promotionActifModel/promotionActifModel";
import PromotionButton from "./PromotionButton";
import { useQuery, UseQueryResult } from "react-query";

const SecondNavBar = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");
  const classes = useStyles();
  const [openSearchModal, setOpenSearchModal] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchData, setSearchData] = React.useState<SearchModel | null>(null);
  const [openSearch, setOpenSearch] = React.useState(false);

  const {
    data,
    error,
    isLoading: loading,
    status,
  }: UseQueryResult<MenyType[], Error> = useQuery<
    MenyType[],
    Error,
    MenyType[],
    string
  >(
    "menu_navbar",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/menu/`
      );
      return response.data;
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  const {
    data: newMenuData,
    status: newMenuStatus,
  }: UseQueryResult<NewMenuModel, Error> = useQuery<
    NewMenuModel,
    Error,
    NewMenuModel,
    string
  >(
    "newMenu",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/nouvelle-collection/actif/`
      );
      return response.data;
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  const {
    data: prmotionData,
    error: errorPromotion,
    isLoading: loadingPromotion,
    status: statusPromotion,
  }: UseQueryResult<PromotionActifModel, Error> = useQuery<
    PromotionActifModel,
    Error,
    PromotionActifModel,
    string
  >(
    "promotion",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/promotion/actif/`
      );
      return response.data;
    },
    {
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  const {
    data: searchResult,
    error: searchError,
    isLoading: searchLoading,
    status: searchStatus,
    refetch: searchRefetch,
  }: UseQueryResult<SearchModel, Error> = useQuery<
    SearchModel,
    Error,
    SearchModel,
    string
  >(
    `searchResult ${searchQuery}`,
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/produit?search=${searchQuery}`
      );
      return response.data;
    },
    {
      enabled: searchQuery.length > 0,
      staleTime: 60 * 60 * 24 * 7 * 1000, // 1 week
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
      onSuccess: (data) => {
        setSearchData(data);
        setOpenSearchModal(true);
      },
    }
  );

  return (
    <Toolbar
      className={classes.secondaryToolbar}
      style={{
        width: "100%",
        maxWidth: "1470px",
        margin: "0 auto",
        padding: "0",
      }}
    >
      <div className={classes.menu__search__container}>
        <MenuIcon
          sx={
            isMobileOrTablet && !openMenu
              ? { display: "block" }
              : { display: "none" }
          }
          onClick={() => setOpenMenu(!openMenu)}
        />
        <CloseIcon
          sx={
            isMobileOrTablet && openMenu
              ? { display: "block" }
              : { display: "none" }
          }
          onClick={() => setOpenMenu(!openMenu)}
        />
        <div
          style={{
            display: isMobileOrTablet ? "block" : "none",
          }}
        >
          {!openSearch && (
            <SearchIcon
              onClick={() => {
                setOpenSearch(!openSearch);
              }}
              style={{ cursor: "pointer", marginRight: "6px" }}
            />
          )}
          {/* <Search /> */}
          {openSearch && (
            <Paper component="form" className="mobile-search-container">
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="recherche "
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  searchRefetch();
                }}
                // on key press enter
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <CloseIcon
                  onClick={() => {
                    setOpenSearch(!openSearch);
                    // searchRefetch();
                  }}
                />
              </IconButton>
            </Paper>
          )}
        </div>
      </div>
      {openSearchModal &&
      searchData &&
      searchData.results?.length &&
      searchQuery.length > 0 ? (
        // setOpenSearchModal, setSearchQuery, setSearchData, searchData,
        <SearchModal
          setOpenSearchModal={setOpenSearchModal}
          setSearchQuery={setSearchQuery}
          setSearchData={setSearchData}
          searchData={searchData}
        />
      ) : null}
      {status === "loading" ? (
        <div>
          {" "}
          <Box
            sx={{
              pt: 0.5,
              display: isMobileOrTablet ? "none" : "flex",
              width: "55vw",
            }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Skeleton width="16%" />
            <Skeleton width="16%" />
            <Skeleton width="16%" />
            <Skeleton width="20%" />
            <Skeleton width="16%" />
          </Box>
        </div>
      ) : status === "error" ? (
        <div className="error_container">
          <span >quelque chose s'est mal passé : {error?.message} </span>
        </div>
      ) : null}
      {isMobileOrTablet && openMenu && (
        <div className="side-dropdown">
          {(openMenu || !isMobileOrTablet) &&
            status === "success" &&
            data.map((item, index) => (
              <MenuButton
                elements={item}
                image={item.photo_principal}
                key={index}
                setOpenMenu={setOpenMenu}
              />
            ))}
          {(openMenu || !isMobileOrTablet) &&
            newMenuStatus === "success" &&
            newMenuData.picture && (
              <NewMenuButton menu={newMenuData} setOpenMenu={setOpenMenu} />
            )}
          {(openMenu || !isMobileOrTablet) &&
            statusPromotion === "success" &&
            prmotionData.picture && (
              <PromotionButton menu={prmotionData} setOpenMenu={setOpenMenu} />
            )}
        </div>
      )}
      {!isMobileOrTablet &&
        status === "success" &&
        data.map((item, index) => (
          <MenuButton
            elements={item}
            image={item.photo_principal}
            key={index}
            setOpenMenu={setOpenMenu}
          />
        ))}

      {!isMobileOrTablet &&
        newMenuStatus === "success" &&
        newMenuData.picture && (
          <NewMenuButton menu={newMenuData} setOpenMenu={setOpenMenu} />
        )}
      {!isMobileOrTablet &&
        statusPromotion === "success" &&
        prmotionData.picture && (
          <PromotionButton menu={prmotionData} setOpenMenu={setOpenMenu} />
        )}
    </Toolbar>
  );
};

export default SecondNavBar;
