import * as React from "react";
import FormCheckBox from "./FormCheckBox";
import RangeSliderCollections from "./RangeSliderCollections";
import SelectColorCollections from "./SelectColorCollections";
import {
  FilterCategories,
  FilterCategoriesList,
} from "../../../types/productsList/FilterCategoriesModel";
import { FilterCategoryGroupModelList } from "../../../types/FilterModels/FilterModel";
import { useEffect, useState } from "react";
import { LoadingCollectionsProductsFilter } from "./LoadingCollectionsProductsFilter";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";

interface Props {
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<FilterCategoryGroupModelList>
  >;
  selectedFilter: FilterCategoryGroupModelList;
  listId: string;
  setFilterColor: React.Dispatch<React.SetStateAction<string[]>>;
  filterColor: string[];
  setTrierPar: React.Dispatch<React.SetStateAction<string>>;
  treirPar: string;
}

const CollectionsProductsFilter = ({
  listId,
  setSelectedFilter,
  selectedFilter,
  setFilterColor,
  filterColor,
}: Props) => {
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");
  const [openFilter, setOpenFilter] = useState(false);

  const {
    data,
    status,
    refetch,
    isFetching,
    isLoading,
  }: UseQueryResult<FilterCategoriesList, Error> = useQuery(
    `filter-${listId}`,
    async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/filtre/?categorie=${listId}`
      );
      return data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      cacheTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  useEffect(() => {
    refetch();
  }, [listId]);

  if (isLoading || isFetching) {
    return <LoadingCollectionsProductsFilter />;
  } else if (status === "error") {
    return <div>error</div>;
  } else {
    return (
      <>
        {isMobileOrTablet && (
          <div className="filterMobile">
            {openFilter ? (
              <FilterAltOffOutlinedIcon
                className="filterMobileIcon"
                onClick={() => setOpenFilter(false)}
              />
            ) : (
              <FilterAltOutlinedIcon
                className="filterMobileIcon"
                onClick={() => setOpenFilter(true)}
              />
            )}
          </div>
        )}

        {status === "success" && (
          <div>
            {(!isMobileOrTablet || openFilter) &&
              data.map((filterCategory: FilterCategories) => (
                <div key={filterCategory.nom}>
                  <FormCheckBox
                    filterCategories={filterCategory}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                </div>
              ))}
            {(!isMobileOrTablet || openFilter) && (
              <SelectColorCollections
                listId={listId}
                setFilterColor={setFilterColor}
                filterColor={filterColor}
              />
            )}
            {(!isMobileOrTablet || openFilter) && <RangeSliderCollections />}
          </div>
        )}
      </>
    );
  }
};

export default CollectionsProductsFilter;
