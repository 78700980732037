import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  NewMenuModel,
  NewMenuModelCategory,
} from "../../types/menuTypes/menuTypes";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { LastChildModel } from "../../types/LastChild/lastChild";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStateContext } from "../../context/stateContext";

<MenuIcon />;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "40px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

// LastChildModel

type Props = {
  menu: NewMenuModel;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function NewMenuButton({ menu, setOpenMenu }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isPromoIsNew, setIsPromoIsNew } = useStateContext();
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const categories: NewMenuModelCategory[] =
    menu.categories as NewMenuModelCategory[];
  const [dropdown, setDropdown] = React.useState(false);
  const [subChildMenu, setSubChildMenu] = React.useState(-1);

  const [lastChild, setLastChild] = React.useState<LastChildModel[]>([]);
  const isMobileOrTablet = useMediaQuery("(max-width: 550px)");

  return (
    <div
      style={{
        width: isMobileOrTablet ? "100%" : "auto",
        display: "flex",
        flexDirection: "column",
        listStyle: "none",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <div style={{ display: "flex", listStyle: "none" }}>
        <li
          style={{
            paddingTop: "22px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",

              cursor: "pointer",
              justifyContent: isMobileOrTablet ? "space-between" : "center",
              width: isMobileOrTablet ? "97vw" : "auto",
            }}
            onMouseEnter={() => setDropdown(true)}
            onMouseLeave={() => setDropdown(false)}
            onClick={() => isMobileOrTablet && setDropdown(!dropdown)}
          >
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              sx={{
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  // borderBottomColor: "black",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  color: "black",
                },
              }}
            >
              <h2 className="menu-button-text">Nouvelle Collection</h2>
            </Button>
            {dropdown ? (
              <KeyboardArrowDownIcon
                style={{
                  width: isMobileOrTablet ? "25px" : "15px",
                  color: "#000",
                }}
              />
            ) : (
              <KeyboardArrowRight
                style={{
                  width: isMobileOrTablet ? "25px" : "15px",
                  color: "#000",
                }}
              />
            )}
          </div>
          {!isMobileOrTablet && dropdown && (
            <div
              style={{
                position: isMobileOrTablet ? "fixed" : "absolute",
                height: isMobileOrTablet ? "40vh" : "auto",
              }}
              className="menu-dropdown"
              onMouseEnter={() => setDropdown(true)}
              onMouseLeave={() => setDropdown(false)}
            >
              <div
                className={classes.menu__stack}
                style={{ marginTop: "20px" }}
              >
                {categories &&
                  categories.map((child) => (
                    <Stack direction="column" spacing={1} key={child.nom}>
                      <h2 className="big-text">
                        {child.nom[0].toUpperCase() + child.nom.slice(1)}
                      </h2>
                      {/* <LastChild child={child} handleClose={handleClose} /> */}
                      {child.children && (
                        <div>
                          {child.children.map((element) => (
                            <Link
                              key={element.id}
                              style={{
                                fontWeight: "600",
                              }}
                              className={
                                window.location.pathname.includes(
                                  element.id.toString()
                                )
                                  ? classes.menu__link__active
                                  : classes.menu__link
                              }
                              to={`collections/${element.id}/${element.id}`}
                            >
                              <h3
                                className="dropdown-link-text"
                                onClick={() => {
                                  setIsPromoIsNew({
                                    isPromo: "",
                                    isNew: "t",
                                  });
                                  setDropdown(false);
                                }}
                              >
                                {element.nom}
                              </h3>
                            </Link>
                          ))}
                        </div>
                      )}
                    </Stack>
                  ))}
                <img
                  className={classes.menu__image}
                  alt="newImage"
                  src={menu.picture}
                />
              </div>
            </div>
          )}
        </li>
      </div>
      {dropdown && isMobileOrTablet && (
        <>
          {categories &&
            categories.map((child) => (
              <Stack
                direction="column"
                spacing={1}
                key={child.id}
                style={{
                  backgroundColor: "white",
                  alignItems: "start",
                  justifyContent: "start",
                  marginTop: "15px",
                  marginLeft: "22px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    justifyContent: "space-between",
                    width: "90vw",
                  }}
                  onClick={() => {
                    setDropdown(true);
                    setSubChildMenu(subChildMenu === child.id ? -1 : child.id);
                    // alert("hello world");
                  }}
                >
                  <h2 className="big-text">
                    {child.nom[0].toUpperCase() + child.nom.slice(1)}
                  </h2>
                  {subChildMenu === child.id ? (
                    <KeyboardArrowDownIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#444",
                      }}
                    />
                  ) : (
                    <KeyboardArrowRight
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#444",
                      }}
                    />
                  )}
                </div>
                {subChildMenu === child.id && child.children && (
                  <div className="last_child_contian">
                    {child.children.map((element) => (
                      <Link
                        key={element.id}
                        style={{
                          fontWeight: "600",
                        }}
                        className={
                          window.location.pathname.includes(
                            element.id.toString()
                          )
                            ? classes.menu__link__active
                            : classes.menu__link
                        }
                        to={`collections/${element.id}/${element.id}`}
                      >
                        <h3
                          className="dropdown-link-text"
                          onClick={() => {
                            setIsPromoIsNew({
                              isPromo: "",
                              isNew: "t",
                            });
                            setDropdown(false);
                            setOpenMenu(false);
                          }}
                        >
                          {element.nom}
                        </h3>
                      </Link>
                    ))}
                  </div>
                )}
              </Stack>
            ))}
        </>
      )}
    </div>
  );
}
