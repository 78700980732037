import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Card, CardMedia, Typography } from "@material-ui/core";
import { ProductModel } from "../../../types/productsList/productsLIst";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

type Props = {
  productsList: ProductModel[] | "error" | "loading";
  index: number;
  size: number;
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,

  height: "100%",
  textAlign: "center",
  color: theme.palette.text.secondary,
  overflow: "hidden",
  cursor: "pointer",

  img: {
    width: "100%",
    height: "100%",
    // full height of the item
    objectFit: "cover",
    objectPosition: "center",
  },
  //hover effect
  "&:hover": {
    img: {
      transform: "scale(1.1)",
      transition: "all 0.5s ease-in-out",
    },
  },
}));

export const PopularCollectionsCard3 = ({
  productsList,
  index,
  size,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Item>
      <Card
        style={{ height: "100%", width: "100%" }}
        onClick={() => {
          productsList !== "loading" &&
            productsList !== "error" &&
            navigate(`/product_details/${productsList[index].id}`);
          window.scrollTo(0, 0);
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <Card
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#F4F4F4",
              top: 0,
            }}
          >
            <div
              style={{
                position: "relative",
                // backgroundColor: "green",
                height: "100%",
                width: "100%",
              }}
            >
              <CardMedia
                style={{ height: "100%", width: "100%" }}
                component="img"
                loading="lazy"
                image={
                  productsList === "loading" ||
                  productsList === "error" ||
                  productsList.length < index
                    ? ""
                    : productsList[index].photo_principal
                }
              />
              <Box
                style={{
                  position: "absolute",
                  color: "white",
                  bottom: "2%",
                  left: "80px",
                  transform: "translateX(-50%)",
                }}
              >
                <Typography
                  variant="h4"
                  className={
                    size === 3
                      ? classes.popular__collections__items__title__3
                      : classes.popular__collections__items__title__63
                  }
                >
                  {productsList === "loading" ||
                  productsList === "error" ||
                  productsList.length < index
                    ? ""
                    : productsList[1].nom.length > 20
                    ? productsList[1].nom
                        .split(" ")
                        .slice(0, 2)
                        .join(" ")
                        .toUpperCase()
                    : productsList[index].nom.toUpperCase()}
                </Typography>
              </Box>
            </div>
          </Card>
        </div>
      </Card>
    </Item>
  );
};
