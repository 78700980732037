import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    position: "relative",
    height: "100%",
  },

  favoriteIcon: {
    position: "absolute",
    top: "10px",
    left: "10px",
    cursor: "pointer",
  },

  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  colorsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    position: "relative",
    gap: ".25rem",
    bottom: "20px",
    width: "100%",
    margin: "0.5rem",
    marginTop: "0rem",
    // marginLeft: "30px",
  },
  check__icon: {
    position: "relative",
    top: "0",
    left: "0",
    color: "white",
    // zIndex: "1",
    height: "50%",
    width: "50%",
  },
  check__icon__hidden: {
    display: "none",
  },
}));
